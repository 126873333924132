import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CompanyInternalApiService } from '@app/shared/api/company-internal-api.service';
import { Company } from '@app/shared/models/company/company';

@Component({
    selector: 'app-company-selector',
    templateUrl: './company-selector.component.html',
    styleUrls: ['./company-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: CompanySelectorComponent,
            multi: true,
        },
    ],
})
export class CompanySelectorComponent implements OnInit, ControlValueAccessor {
    @Input() required = false;

    value: number;

    onChange: Function;

    companies: Company[];

    constructor(private companyInternalApiService: CompanyInternalApiService) {}

    async ngOnInit() {
        this.companies = await this.companyInternalApiService.getAll();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {}

    setDisabledState(isDisabled: boolean): void {}

    writeValue(value: number): void {
        this.value = value;
    }
    
    onOptionSelect(value: any) {
        if (this.onChange) {
            this.onChange(value);
        }
    }
}
