import { Filter } from './filter';

export class MoreOrLess extends Filter<{ gte: number; lte: number }> {
    controlType = 'more_or_less';
    value = { gte: null, lte: null };

    isEmpty(): boolean {
        return this.value === null || (this.value.gte === null && this.value.lte === null);
    }
}
