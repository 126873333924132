import { ConnectorInterface } from './connector.interface';
import { RpcRequest } from '../request';
import { RpcResponse } from '../response';

/**
 * Local connector options.
 */
export interface RpcLocalConnectorOptions {
    data: any;
}

/**
 * Local connector.
 */
export class LocalConnector implements ConnectorInterface {
    /**
     * @param options
     */
    constructor(protected options: RpcLocalConnectorOptions) {}

    /**
     * Exec request.
     */
    public exec(request: RpcRequest): Promise<RpcResponse | null> {
        console.log('RPC: ->', request.method, request.params);

        return new Promise((resolve, reject) => {
            if (!this.options.data[request.method]) {
                reject({ error: { code: -32601, message: 'Method not found' } });
                console.log('RPC ERROR: <- ', request.method, { error: { code: -32601, message: 'Method not found' } });
            }

            if (this.options.data[request.method][JSON.stringify(request.params)]) {
                resolve(this.options.data[request.method][JSON.stringify(request.params)]);
                console.log(
                    'RPC: <- ',
                    request.method,
                    this.options.data[request.method][JSON.stringify(request.params)],
                );
            } else {
                resolve(this.options.data[request.method]);
                console.log('RPC: <- ', request.method, this.options.data[request.method]);
            }
        });
    }

    /**
     * Delete token
     */
    deleteToken(): void {
        // not use
    }

    /**
     * Set token
     */
    setToken(token: string): void {
        // not use
    }

    /**
     * Get connector options
     */
    getOptions() {
        return 'Not support';
    }
}
