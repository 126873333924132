<mat-form-field appearance="outline" class="date-select">
    <mat-label>{{ label }}</mat-label>
    <input
        matInput
        [matDatepicker]="picker"
        type="datetime"
        [required]="required"
        [min]="min"
        (ngModelChange)="updateValue($event)"
        [(ngModel)]="value"
        #date="ngModel"
    />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>

    <mat-error *ngIf="date.hasError('matDatepickerMin')">
        Дата меньше минимальной
    </mat-error>
</mat-form-field>
