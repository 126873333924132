import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { GameInternalApiServise } from '@app/shared/api/game-internal-api.servise';
import { Game } from '@app/shared/models/game/game';

@Component({
    selector: 'app-game-selector',
    templateUrl: './game-selector.component.html',
    styleUrls: ['./game-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: GameSelectorComponent,
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GameSelectorComponent implements OnInit, ControlValueAccessor {
    @Input() ngModelField: keyof Game = 'id';
    @Input() required = false;

    onChange: Function;
    value: string | number | null = null;

    games: Game[];

    constructor(private gameInternalApiServise: GameInternalApiServise) {}

    async ngOnInit() {
        this.games = await this.gameInternalApiServise.getAll(true);
    }

    selectValue(value: any) {
        if (this.onChange) {
            this.onChange(value);
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {}

    setDisabledState(isDisabled: boolean): void {}

    writeValue(value: any): void {
        this.value = value;
    }
}
