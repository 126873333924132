<mat-form-field appearance="outline" fxFlex="1 1 100%">
    <mat-label>Цены</mat-label>
    <mat-select [(value)]="value" (selectionChange)="selectValue($event.value)">
        <mat-option [value]="null">Не выбрано</mat-option>
        <mat-option *ngFor="let option of options" [value]="option[controlField]">
            {{ option.name }}
        </mat-option>
    </mat-select>
    <mat-error *ngIf="selectControl.hasError('required')">
        Это поле обязательно для заполнения
    </mat-error>
</mat-form-field>
