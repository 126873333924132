import { Injectable } from '@angular/core';
import { Rpc } from '@app/shared/rpc';
import { Banner } from '@app/shared/models/banner/banner';
import { Variable } from '@app/shared/models/banner/variable';
import { PaginationList } from '@models/pagination-list';
import { CollectionType } from '@models/banner/collection-type';
import { Collection } from '@models/banner/collection';

@Injectable({
    providedIn: 'root',
})
export class BannerApiService {
    constructor(protected rpc: Rpc) {}

    get(id: number): Promise<Banner> {
        return this.rpc.call('banner.banner.get', { id: id });
    }

    list(pageIndex = 0, pageSize = 25, status = 'active', search = null): Promise<PaginationList<Banner>> {
        return this.rpc.call('banner.banner.list', {
            pageIndex: pageIndex,
            pageSize: pageSize,
            status: status,
            search: search,
        });
    }

    getAll(status = 'active', search = null): Promise<Banner[]> {
        return this.rpc.call('banner.banner.getAll', { status: status, search: search });
    }

    create(banner: Banner): Promise<Banner> {
        return this.rpc.call('banner.banner.create', {
            name: banner.name,
            spot: banner.spot,
            html: banner.html,
            scss: banner.scss,
            variables: banner.variables,
        });
    }

    update(banner: Banner): Promise<Banner> {
        return this.rpc.call('banner.banner.update', {
            id: banner.id,
            name: banner.name,
            spot: banner.spot,
            html: banner.html,
            scss: banner.scss,
            variables: banner.variables,
        });
    }

    compile(
        html: string,
        scss: string,
        variables: Variable[],
    ): Promise<{
        html: string;
        css: string;
    }> {
        return this.rpc.call('banner.compile', {
            html: html,
            scss: scss,
            variables: this.convertVariables(variables),
        });
    }

    private convertVariables(variables: Variable[]) {
        const variablesValues: { [key: string]: string } = {};

        variables.forEach(variable => {
            variablesValues[variable.name] = variable.value;
        });

        return variablesValues;
    }

    getAllCollectionType(): Promise<CollectionType[]> {
        return this.rpc.call('banner.collectionType.getAll');
    }

    createCollectionType(name: string, slug: string, spotId: number): Promise<CollectionType[]> {
        return this.rpc.call('banner.collectionType.create', { name: name, slug: slug, spotId: spotId });
    }

    updateCollectionType(id: number, name: string, slug: string): Promise<CollectionType[]> {
        return this.rpc.call('banner.collectionType.update', { id: id, name: name, slug: slug });
    }

    getAllCollection(): Promise<Collection[]> {
        return this.rpc.call('banner.collection.getAll');
    }

    collectionChangeState(id: number, enabled: boolean): Promise<Collection[]> {
        return this.rpc.call('banner.collection.changeState', { id: id, enabled: enabled });
    }

    createCollection(name: string, cohort: string, typeId: number, bannersIds: number[]): Promise<Collection[]> {
        return this.rpc.call('banner.collection.create', {
            name: name,
            cohort: cohort,
            typeId: typeId,
            bannersIds: bannersIds,
        });
    }

    updateCollection(id: number, name: string, cohort: string, bannersIds: number[]): Promise<Collection[]> {
        return this.rpc.call('banner.collection.update', {
            id: id,
            name: name,
            cohort: cohort,
            bannersIds: bannersIds,
        });
    }

    deleteCollection(id: number): Promise<boolean> {
        return this.rpc.call('banner.collection.delete', {
            id: id,
        });
    }

    setStatus(id: number, status: string): Promise<boolean> {
        return this.rpc.call('banner.banner.setStatus', {
            id: id,
            status: status,
        });
    }
}
