<mat-form-field appearance="outline" class="ability-selector">
    <mat-label>Способность</mat-label>
    <mat-select [(ngModel)]="value" (ngModelChange)="change()" name="slug" [required]="required">
        <mat-option [value]="null">
            --
        </mat-option>
        <mat-option *ngFor="let ability of abilities" [value]="ability.id">
            {{ ability.slug }}
        </mat-option>
    </mat-select>
</mat-form-field>