<div fxLayout="column" fxFlexFill>
    <mat-toolbar class="portal-side-menu-top-toolbar">
        <mat-toolbar-row fxLayout="row">
            <img class="portal-toolbar-brand" src="assets/logo/wikium.svg" />
        </mat-toolbar-row>
    </mat-toolbar>

    <div class="portal-sidnav-menu portal-hide-scrollbars" fxFlex="nogrow noshrink">
        <!-- home & dashboard menu -->
        <!--<mat-expansion-panel expanded="true">-->
        <!--<mat-expansion-panel-header>-->
        <!--<mat-panel-title>-->
        <!--<mat-icon>home</mat-icon>-->
        <!--<h3>Dashboards</h3>-->
        <!--</mat-panel-title>-->
        <!--</mat-expansion-panel-header>-->
        <!--<mat-nav-list>-->
        <!--<a mat-list-item routerLink="/dashboards/analytics" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<mat-icon matListIcon>insert_chart</mat-icon>-->
        <!--<h3 matLine>Analytics</h3>-->
        <!--<strong class="portal-sidnav-menu__end_detail">4</strong>-->
        <!--</a>-->
        <!--<a mat-list-item routerLink="/dashboards/ecommerce" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<mat-icon matListIcon>shopping_cart</mat-icon>-->
        <!--<h3 matLine>Ecommerce</h3>-->
        <!--</a>-->
        <!--<a mat-list-item routerLink="/dashboards/crypto" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<mat-icon matListIcon>euro_symbol</mat-icon>-->
        <!--<h3 matLine>CryptoCurrency</h3>-->
        <!--</a>-->
        <!--<a mat-list-item routerLink="/dashboards/project" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<mat-icon matListIcon>event_note</mat-icon>-->
        <!--<h3 matLine>Project</h3>-->
        <!--</a>-->
        <!--</mat-nav-list>-->
        <!--</mat-expansion-panel>-->

        <!-- Dashboard -->

        <mat-nav-list>
            <a mat-list-item routerLink="/dashboard" routerLinkActive="portal-menu--active">
                <mat-icon matListIcon>home</mat-icon>
                <h3 matLine>Обзор</h3>
            </a>
        </mat-nav-list>

        <!-- Письма -->
        <mat-expansion-panel
            *appIfRole="[
                'ROLE_ADMIN',
                'ROLE_EMAIL',
                'ROLE_EMAIL_VIEW',
                'ROLE_EMAIL_SCHEDULE',
                'ROLE_EMAIL_SCHEDULE_VIEW'
            ]"
        >
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon>email</mat-icon>
                    <h3>Письма</h3>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list>
                <a
                    mat-list-item
                    routerLink="/emails/templates"
                    routerLinkActive="portal-menu--active"
                    *appIfRole="['ROLE_ADMIN', 'ROLE_EMAIL', 'ROLE_EMAIL_VIEW']"
                >
                    <h3 matLine>Список шаблонов</h3>
                </a>
                <a
                    mat-list-item
                    routerLink="/emails/scheduler"
                    routerLinkActive="portal-menu--active"
                    *appIfRole="['ROLE_ADMIN', 'ROLE_EMAIL_SCHEDULE', 'ROLE_EMAIL_SCHEDULE_VIEW']"
                >
                    <h3 matLine>Расписание</h3>
                </a>
            </mat-nav-list>
        </mat-expansion-panel>

        <!-- Акции -->
        <mat-expansion-panel *appIfRole="['ROLE_ADMIN', 'ROLE_STOCK', 'ROLE_STOCK_VIEW']">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon>local_play</mat-icon>
                    <h3>Акции</h3>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list>
                <a
                    mat-list-item
                    routerLink="/stocks/list"
                    routerLinkActive="portal-menu--active"
                    *appIfRole="['ROLE_ADMIN', 'ROLE_STOCK', 'ROLE_STOCK_VIEW']"
                >
                    <h3 matLine>Список</h3>
                </a>
            </mat-nav-list>
            <mat-nav-list>
                <a
                    mat-list-item
                    routerLink="/stocks/schedule"
                    routerLinkActive="portal-menu--active"
                    *appIfRole="['ROLE_ADMIN', 'ROLE_STOCK', 'ROLE_STOCK_VIEW']"
                >
                    <h3 matLine>Расписание</h3>
                </a>
            </mat-nav-list>
            <mat-nav-list>
                <a
                    mat-list-item
                    routerLink="/stocks/timer"
                    routerLinkActive="portal-menu--active"
                    *appIfRole="['ROLE_ADMIN', 'ROLE_STOCK']"
                >
                    <h3 matLine>Товар дня</h3>
                </a>
            </mat-nav-list>
        </mat-expansion-panel>

        <!-- Cohort -->
        <mat-expansion-panel *appIfRole="['ROLE_ADMIN', 'ROLE_COHORT']">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon>contacts</mat-icon>
                    <h3>Когорты</h3>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list>
                <a mat-list-item routerLink="/cohort/list">
                    <h3 matLine>Список</h3>
                </a>
            </mat-nav-list>
        </mat-expansion-panel>

        <!-- Контент -->
        <mat-expansion-panel *appIfRole="['ROLE_ADMIN', 'ROLE_UPLOAD', 'ROLE_PAGE', 'ROLE_GAME', 'ROLE_CHALLENGE']">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon>attach_file</mat-icon>
                    <h3>Контент</h3>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list *appIfRole="['ROLE_ADMIN', 'ROLE_UPLOAD']">
                <a mat-list-item routerLink="/uploads/manager">
                    <h3 matLine>Загрузки</h3>
                </a>
            </mat-nav-list>
            <mat-nav-list *appIfRole="['ROLE_ADMIN', 'ROLE_PAGE']">
                <a mat-list-item routerLink="/page/list">
                    <h3 matLine>Страницы</h3>
                </a>
            </mat-nav-list>
            <mat-nav-list *appIfRole="['ROLE_ADMIN', 'ROLE_PAGE']">
                <a mat-list-item routerLink="/tilda/list">
                    <h3 matLine>Tilda лендинги</h3>
                </a>
            </mat-nav-list>
            <mat-nav-list *appIfRole="['ROLE_ADMIN', 'ROLE_GAME']">
                <a mat-list-item routerLink="/game/list">
                    <h3 matLine>Тренажеры</h3>
                </a>
            </mat-nav-list>
            <mat-nav-list *appIfRole="['ROLE_ADMIN', 'ROLE_CHALLENGE']">
                <a mat-list-item routerLink="/challenge/list">
                    <h3 matLine>Соревнования</h3>
                </a>
            </mat-nav-list>
        </mat-expansion-panel>

        <!-- Cohort -->
        <mat-expansion-panel *appIfRole="['ROLE_ADMIN', 'ROLE_BANNER']">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon>web</mat-icon>
                    <h3>Баннеры</h3>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list>
                <a mat-list-item routerLink="/banner/list" routerLinkActive="portal-menu--active">
                    <h3 matLine>Баннеры</h3>
                </a>
                <a mat-list-item routerLink="/banner/spot/list" routerLinkActive="portal-menu--active">
                    <h3 matLine>Места показа</h3>
                </a>
                <a mat-list-item routerLink="/banner/popular" routerLinkActive="portal-menu--active">
                    <h3 matLine>Популярное на Train</h3>
                </a>
                <a mat-list-item routerLink="/banner/collection/type" routerLinkActive="portal-menu--active">
                    <h3 matLine>Типы коллекций</h3>
                </a>
                <a
                    mat-list-item
                    routerLink="/banner/collection"
                    routerLinkActive="portal-menu--active"
                    [routerLinkActiveOptions]="{ exact: true }"
                >
                    <h3 matLine>Коллекций</h3>
                </a>
            </mat-nav-list>
        </mat-expansion-panel>

        <mat-expansion-panel *appIfRole="['ROLE_ADMIN', 'ROLE_SETTINGS_EMOJI']">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon>settings</mat-icon>
                    <h3>Настройки сайта</h3>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list>
                <a
                    mat-list-item
                    routerLink="/settings/emojipack/list"
                    routerLinkActive="portal-menu--active"
                    *appIfRole="['ROLE_ADMIN', 'ROLE_SETTINGS_EMOJI']"
                >
                    <h3 matLine>Emoji в дуэлях</h3>
                </a>
            </mat-nav-list>
        </mat-expansion-panel>

        <mat-expansion-panel *appIfRole="['ROLE_ADMIN', 'ROLE_PAYMENT']">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon>money</mat-icon>
                    <h3>Платежи</h3>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list>
                <a
                    mat-list-item
                    routerLink="/payment/list"
                    routerLinkActive="portal-menu--active"
                    *appIfRole="['ROLE_ADMIN', 'ROLE_PAYMENT']"
                >
                    <h3 matLine>Список платежей</h3>
                </a>
            </mat-nav-list>
        </mat-expansion-panel>

        <mat-expansion-panel
            *appIfRole="['ROLE_ADMIN', 'ROLE_NOTIFICATION', 'ROLE_NOTIFICATION_PUSH', 'ROLE_NOTIFICATION_SITE']"
        >
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon>notifications</mat-icon>
                    <h3>Уведомления</h3>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list>
                <a
                    *appIfRole="['ROLE_ADMIN', 'ROLE_NOTIFICATION']"
                    mat-list-item
                    routerLink="/notification/list"
                    routerLinkActive="portal-menu--active"
                >
                    <h3 matLine>Список уведомлений</h3>
                </a>
            </mat-nav-list>

            <!-- push уведомления -->
            <mat-nav-list>
                <a
                    *appIfRole="['ROLE_ADMIN', 'ROLE_NOTIFICATION_PUSH']"
                    mat-list-item
                    routerLink="/notification/push"
                    routerLinkActive="portal-menu--active"
                >
                    <h3 matLine>Расписание push уведомлений</h3>
                </a>
            </mat-nav-list>

            <!-- Уведомления на сайте -->
            <mat-nav-list>
                <a
                    *appIfRole="['ROLE_ADMIN', 'ROLE_NOTIFICATION_SITE']"
                    mat-list-item
                    routerLink="/notification/website"
                    routerLinkActive="portal-menu--active"
                >
                    <h3 matLine>Уведомления на сайте</h3>
                </a>
            </mat-nav-list>
        </mat-expansion-panel>

        <mat-expansion-panel *appIfRole="['ROLE_ADMIN', 'ROLE_COURSE', 'ROLE_COURSE_DIALOGS']">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon>list_alt</mat-icon>
                    <h3>Курсы</h3>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list>
                <a
                    mat-list-item
                    routerLink="/course/list"
                    routerLinkActive="portal-menu--active"
                    *appIfRole="['ROLE_ADMIN', 'ROLE_COURSE']"
                >
                    <h3 matLine>Курсы</h3>
                </a>
                <a
                    mat-list-item
                    routerLink="/course/course-test"
                    routerLinkActive="portal-menu--active"
                    *appIfRole="['ROLE_ADMIN', 'ROLE_COURSE']"
                >
                    <h3 matLine>Тесты</h3>
                </a>
                <a
                    mat-list-item
                    routerLink="/course/user-access"
                    routerLinkActive="portal-menu--active"
                    *appIfRole="['ROLE_ADMIN', 'ROLE_COURSE']"
                >
                    <h3 matLine>Доступы пользователей</h3>
                </a>
                <a
                    mat-list-item
                    routerLink="/course/course-dialogs"
                    routerLinkActive="portal-menu--active"
                    *appIfRole="['ROLE_ADMIN', 'ROLE_COURSE', 'ROLE_COURSE_DIALOGS']"
                >
                    <h3 matLine>Диалоги</h3>
                </a>
            </mat-nav-list>
        </mat-expansion-panel>

        <mat-expansion-panel *appIfRole="['ROLE_ADMIN', 'ROLE_REPORT']">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon>description</mat-icon>
                    <h3>Отчеты</h3>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list>
                <a
                    mat-list-item
                    routerLink="/report/orders-cohorts"
                    routerLinkActive="portal-menu--active"
                    *appIfRole="['ROLE_ADMIN', 'ROLE_REPORT']"
                >
                    <h3 matLine>Заказы по когортам</h3>
                </a>
            </mat-nav-list>
            <mat-nav-list>
                <a
                    mat-list-item
                    routerLink="/report/orders"
                    routerLinkActive="portal-menu--active"
                    *appIfRole="['ROLE_ADMIN', 'ROLE_REPORT']"
                >
                    <h3 matLine>Заказы</h3>
                </a>
            </mat-nav-list>
            <mat-nav-list>
                <a
                    mat-list-item
                    routerLink="/report/orders-products"
                    routerLinkActive="portal-menu--active"
                    *appIfRole="['ROLE_ADMIN', 'ROLE_REPORT']"
                >
                    <h3 matLine>Заказы по продуктам</h3>
                </a>
            </mat-nav-list>
            <mat-nav-list>
                <a
                    mat-list-item
                    routerLink="/report/registrations"
                    routerLinkActive="portal-menu--active"
                    *appIfRole="['ROLE_ADMIN', 'ROLE_REPORT']"
                >
                    <h3 matLine>Регистрации</h3>
                </a>
            </mat-nav-list>
            <mat-nav-list>
                <a
                    mat-list-item
                    routerLink="/report/trains"
                    routerLinkActive="portal-menu--active"
                    *appIfRole="['ROLE_ADMIN', 'ROLE_REPORT']"
                >
                    <h3 matLine>Пройденные ПР</h3>
                </a>
            </mat-nav-list>
            <mat-nav-list>
                <a
                    mat-list-item
                    routerLink="/report/orders-comparation"
                    routerLinkActive="portal-menu--active"
                    *appIfRole="['ROLE_ADMIN', 'ROLE_REPORT']"
                >
                    <h3 matLine>Сравнение по заказам</h3>
                </a>
            </mat-nav-list>
            <mat-nav-list>
                <a
                    mat-list-item
                    routerLink="/report/orders-dashboard"
                    routerLinkActive="portal-menu--active"
                    *appIfRole="['ROLE_ADMIN', 'ROLE_REPORT']"
                >
                    <h3 matLine>Дашборд продаж</h3>
                </a>
            </mat-nav-list>
            <mat-nav-list>
                <a
                    mat-list-item
                    routerLink="/report/prices"
                    routerLinkActive="portal-menu--active"
                    *appIfRole="['ROLE_ADMIN', 'ROLE_REPORT']"
                >
                    <h3 matLine>Средние цены</h3>
                </a>
            </mat-nav-list>
        </mat-expansion-panel>

        <mat-expansion-panel *appIfRole="['ROLE_ADMIN', 'ROLE_USERS', 'ROLE_COMPANY']">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon>account_circle</mat-icon>
                    <h3>Пользователи</h3>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-nav-list>
                <a
                    mat-list-item
                    routerLink="/user/list"
                    routerLinkActive="portal-menu--active"
                    *appIfRole="['ROLE_ADMIN', 'ROLE_USERS']"
                >
                    <h3 matLine>Пользователи</h3>
                </a>
            </mat-nav-list>
            <mat-nav-list>
                <a
                    mat-list-item
                    routerLink="/company/list"
                    routerLinkActive="portal-menu--active"
                    *appIfRole="['ROLE_ADMIN', 'ROLE_COMPANY']"
                >
                    <h3 matLine>Компании</h3>
                </a>
            </mat-nav-list>
        </mat-expansion-panel>

        <mat-expansion-panel *appIfRole="['ROLE_ADMIN', 'ROLE_ORDER']">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon>receipt</mat-icon>
                    <h3>Заказы</h3>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-nav-list>
                <a
                    mat-list-item
                    routerLink="/orders/list"
                    routerLinkActive="portal-menu--active"
                    *appIfRole="['ROLE_ADMIN', 'ROLE_ORDER']"
                >
                    <h3 matLine>Поиск по заказам</h3>
                </a>
                <a
                    mat-list-item
                    routerLink="/orders/installments"
                    routerLinkActive="portal-menu--active"
                    *appIfRole="['ROLE_ADMIN', 'ROLE_ORDER']"
                >
                    <h3 matLine>Рассрочки</h3>
                </a>
            </mat-nav-list>
        </mat-expansion-panel>

        <mat-expansion-panel *appIfRole="['ROLE_ADMIN']">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon>analytics</mat-icon>
                    <h3>Логи пользователей</h3>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list>
                <a
                    mat-list-item
                    routerLink="/audit/list"
                    routerLinkActive="portal-menu--active"
                    *appIfRole="['ROLE_ADMIN']"
                >
                    <h3 matLine>Список логов</h3>
                </a>
            </mat-nav-list>
        </mat-expansion-panel>

        <mat-expansion-panel *appIfRole="['ROLE_ADMIN', 'ROLE_COMPETENCE']">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon>group_work</mat-icon>
                    <h3>Компетенции / способности</h3>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list>
                <a mat-list-item routerLink="/bpiability/list" routerLinkActive="portal-menu--active">
                    <h3 matLine>Способности</h3>
                </a>
            </mat-nav-list>
            <mat-nav-list>
                <a mat-list-item routerLink="/competence/list" routerLinkActive="portal-menu--active">
                    <h3 matLine>Компетенции</h3>
                </a>
            </mat-nav-list>
        </mat-expansion-panel>

        <!-- Admin -->
        <mat-expansion-panel *appIfRole="'ROLE_ADMIN'">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon>settings</mat-icon>
                    <h3>Администрирование</h3>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list>
                <a mat-list-item routerLink="/admin/users" routerLinkActive="portal-menu--active">
                    <h3 matLine>Пользователи</h3>
                </a>
            </mat-nav-list>
        </mat-expansion-panel>

        <!-- apps menu -->

        <!--<h3 class="portal-sidnav-menu__subheader">Apps</h3>-->
        <!--<mat-expansion-panel>-->
        <!--<mat-expansion-panel-header>-->
        <!--<mat-panel-title>-->
        <!--<mat-icon>desktop_windows</mat-icon>-->
        <!--<h3>Apps</h3>-->
        <!--</mat-panel-title>-->
        <!--</mat-expansion-panel-header>-->
        <!--<mat-nav-list>-->
        <!--<a mat-list-item [routerLink]="['/apps/email', { outlets: { list: 'inbox', messages: 'none' } }]" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<mat-icon matListIcon>email</mat-icon>-->
        <!--<h3 matLine>Email</h3>-->
        <!--</a>-->
        <!--<a mat-list-item routerLink="/apps/todo" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<mat-icon matListIcon>check_circle</mat-icon>-->
        <!--<h3 matLine>Todo</h3>-->
        <!--</a>-->
        <!--<a mat-list-item routerLink="/apps/spotify" routerLinkActive="portal-menu&#45;&#45;active" [routerLinkActiveOptions]="{ exact: true }">-->
        <!--<mat-icon fontSet="fa" fontIcon="fa-spotify" matListIcon></mat-icon>-->
        <!--<h3 matLine>Spotify</h3>-->
        <!--</a>-->
        <!--<a mat-list-item routerLink="/apps/maps" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<mat-icon matListIcon>pin_drop</mat-icon>-->
        <!--<h3 matLine>Maps</h3>-->
        <!--</a>-->
        <!--<a mat-list-item routerLink="/apps/chat" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<mat-icon matListIcon>chat</mat-icon>-->
        <!--<h3 matLine>Chat</h3>-->
        <!--</a>-->
        <!--<a mat-list-item routerLink="/apps/calendar" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<mat-icon matListIcon>date_range</mat-icon>-->
        <!--<h3 matLine>Calendar</h3>-->
        <!--</a>-->
        <!--<a mat-list-item routerLink="/apps/contacts" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<mat-icon matListIcon>face</mat-icon>-->
        <!--<h3 matLine>Contacts</h3>-->
        <!--</a>-->
        <!--<a mat-list-item routerLink="/apps/notes" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<mat-icon matListIcon>event_note</mat-icon>-->
        <!--<h3 matLine>Notes</h3>-->
        <!--</a>-->
        <!--</mat-nav-list>-->
        <!--</mat-expansion-panel>-->

        <!--&lt;!&ndash; pages menu &ndash;&gt;-->

        <!--<h3 class="portal-sidnav-menu__subheader">Pages</h3>-->
        <!--<mat-expansion-panel>-->
        <!--<mat-expansion-panel-header>-->
        <!--<mat-panel-title>-->
        <!--<mat-icon>perm_identity</mat-icon>-->
        <!--<h3>Authentication</h3>-->
        <!--</mat-panel-title>-->
        <!--</mat-expansion-panel-header>-->
        <!--<mat-nav-list>-->
        <!--<a mat-list-item routerLink="/external/login" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<h3 matLine>Login</h3>-->
        <!--</a>-->
        <!--<a mat-list-item routerLink="/external/register" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<h3 matLine>Register</h3>-->
        <!--</a>-->
        <!--<a mat-list-item routerLink="/external/forgot-password" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<h3 matLine>Forgot Password</h3>-->
        <!--</a>-->
        <!--<a mat-list-item routerLink="/pages/profile" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<h3 matLine>Profile</h3>-->
        <!--</a>-->
        <!--<a mat-list-item routerLink="/external/lock" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<h3 matLine>Lock Page</h3>-->
        <!--</a>-->
        <!--</mat-nav-list>-->
        <!--</mat-expansion-panel>-->
        <!--<mat-expansion-panel>-->
        <!--<mat-expansion-panel-header>-->
        <!--<mat-panel-title>-->
        <!--<mat-icon>error</mat-icon>-->
        <!--<h3>Errors</h3>-->
        <!--</mat-panel-title>-->
        <!--</mat-expansion-panel-header>-->
        <!--<mat-nav-list>-->
        <!--<a mat-list-item routerLink="/external/404" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<h3 matLine>404</h3>-->
        <!--</a>-->
        <!--<a mat-list-item routerLink="/external/500" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<h3 matLine>500</h3>-->
        <!--</a>-->
        <!--</mat-nav-list>-->
        <!--</mat-expansion-panel>-->

        <!--&lt;!&ndash; user interface menu &ndash;&gt;-->

        <!--<h3 class="portal-sidnav-menu__subheader">User Interface</h3>-->
        <!--<mat-nav-list>-->
        <!--<a mat-list-item routerLink="/ui/typography" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<mat-icon matListIcon>text_format</mat-icon>-->
        <!--<h3 matLine>Typography</h3>-->
        <!--</a>-->
        <!--<a mat-list-item routerLink="/ui/colors" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<mat-icon matListIcon>palette</mat-icon>-->
        <!--<h3 matLine>Colors</h3>-->
        <!--</a>-->
        <!--</mat-nav-list>-->

        <!--&lt;!&ndash; elements menu &ndash;&gt;-->

        <!--<h3 class="portal-sidnav-menu__subheader">Elements</h3>-->
        <!--<mat-expansion-panel>-->
        <!--<mat-expansion-panel-header>-->
        <!--<mat-panel-title>-->
        <!--<mat-icon>extension</mat-icon>-->
        <!--<h3>Form Controls</h3>-->
        <!--</mat-panel-title>-->
        <!--</mat-expansion-panel-header>-->
        <!--<mat-nav-list>-->
        <!--<a mat-list-item routerLink="/elements/autocomplete" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<h3 matLine>Autocomplete</h3>-->
        <!--</a>-->
        <!--<a mat-list-item routerLink="/elements/checkbox" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<h3 matLine>Checkbox</h3>-->
        <!--</a>-->
        <!--<a mat-list-item routerLink="/elements/datepicker" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<h3 matLine>Datepicker</h3>-->
        <!--</a>-->
        <!--<a mat-list-item routerLink="/elements/form-field" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<h3 matLine>Form Field</h3>-->
        <!--</a>-->
        <!--<a mat-list-item routerLink="/elements/input" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<h3 matLine>Input</h3>-->
        <!--</a>-->
        <!--<a mat-list-item routerLink="/elements/radio" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<h3 matLine>Radio</h3>-->
        <!--</a>-->
        <!--<a mat-list-item routerLink="/elements/select" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<h3 matLine>Select</h3>-->
        <!--</a>-->
        <!--<a mat-list-item routerLink="/elements/slider" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<h3 matLine>Slider</h3>-->
        <!--</a>-->
        <!--<a mat-list-item routerLink="/elements/toggle" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<h3 matLine>Slide Toggle</h3>-->
        <!--</a>-->
        <!--</mat-nav-list>-->
        <!--</mat-expansion-panel>-->
        <!--<mat-expansion-panel>-->
        <!--<mat-expansion-panel-header>-->
        <!--<mat-panel-title>-->
        <!--<mat-icon>menu</mat-icon>-->
        <!--<h3>Navigation</h3>-->
        <!--</mat-panel-title>-->
        <!--</mat-expansion-panel-header>-->
        <!--<mat-nav-list>-->
        <!--<a mat-list-item routerLink="/elements/menu" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<h3 matLine>Menu</h3>-->
        <!--</a>-->
        <!--<a mat-list-item routerLink="/elements/sidenav" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<h3 matLine>Sidenav</h3>-->
        <!--</a>-->
        <!--<a mat-list-item routerLink="/elements/toolbar" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<h3 matLine>Toolbar</h3>-->
        <!--</a>-->
        <!--</mat-nav-list>-->
        <!--</mat-expansion-panel>-->
        <!--<mat-expansion-panel>-->
        <!--<mat-expansion-panel-header>-->
        <!--<mat-panel-title>-->
        <!--<mat-icon>view_module</mat-icon>-->
        <!--<h3>Layout</h3>-->
        <!--</mat-panel-title>-->
        <!--</mat-expansion-panel-header>-->
        <!--<mat-nav-list>-->
        <!--<a mat-list-item routerLink="/elements/list" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<h3 matLine>List</h3>-->
        <!--</a>-->
        <!--<a mat-list-item routerLink="/elements/grid-list" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<h3 matLine>Grid List</h3>-->
        <!--</a>-->
        <!--<a mat-list-item routerLink="/elements/card" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<h3 matLine>Card</h3>-->
        <!--</a>-->
        <!--<a mat-list-item routerLink="/elements/stepper" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<h3 matLine>Stepper</h3>-->
        <!--</a>-->
        <!--<a mat-list-item routerLink="/elements/tabs" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<h3 matLine>Tabs</h3>-->
        <!--</a>-->
        <!--<a mat-list-item routerLink="/elements/expansion-panel" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<h3 matLine>Expansion Panel</h3>-->
        <!--</a>-->
        <!--</mat-nav-list>-->
        <!--</mat-expansion-panel>-->
        <!--<mat-expansion-panel>-->
        <!--<mat-expansion-panel-header>-->
        <!--<mat-panel-title>-->
        <!--<mat-icon>info</mat-icon>-->
        <!--<h3>Buttons & Indicators</h3>-->
        <!--</mat-panel-title>-->
        <!--</mat-expansion-panel-header>-->
        <!--<mat-nav-list>-->
        <!--<a mat-list-item routerLink="/elements/buttons" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<h3 matLine>Buttons</h3>-->
        <!--</a>-->
        <!--<a mat-list-item routerLink="/elements/button-toggle" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<h3 matLine>Button Toggle</h3>-->
        <!--</a>-->
        <!--<a mat-list-item routerLink="/elements/chips" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<h3 matLine>Chips</h3>-->
        <!--</a>-->
        <!--<a mat-list-item routerLink="/elements/mat-icon" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<h3 matLine>Material Icons</h3>-->
        <!--</a>-->
        <!--<a mat-list-item routerLink="/elements/fa-icon" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<h3 matLine>Font Awesome Icons</h3>-->
        <!--</a>-->
        <!--<a mat-list-item routerLink="/elements/progress-spinner" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<h3 matLine>Progress Spinner</h3>-->
        <!--</a>-->
        <!--<a mat-list-item routerLink="/elements/progress-bar" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<h3 matLine>Progress Bar</h3>-->
        <!--</a>-->
        <!--</mat-nav-list>-->
        <!--</mat-expansion-panel>-->
        <!--<mat-expansion-panel>-->
        <!--<mat-expansion-panel-header>-->
        <!--<mat-panel-title>-->
        <!--<mat-icon>feedback</mat-icon>-->
        <!--<h3>Popups & Modals</h3>-->
        <!--</mat-panel-title>-->
        <!--</mat-expansion-panel-header>-->
        <!--<mat-nav-list>-->
        <!--<a mat-list-item routerLink="/elements/dialog" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<h3 matLine>Dialog</h3>-->
        <!--</a>-->
        <!--<a mat-list-item routerLink="/elements/tooltip" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<h3 matLine>Tooltip</h3>-->
        <!--</a>-->
        <!--<a mat-list-item routerLink="/elements/snackbar" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<h3 matLine>Snackbar</h3>-->
        <!--</a>-->
        <!--</mat-nav-list>-->
        <!--</mat-expansion-panel>-->
        <!--<mat-expansion-panel>-->
        <!--<mat-expansion-panel-header>-->
        <!--<mat-panel-title>-->
        <!--<mat-icon>reorder</mat-icon>-->
        <!--<h3>Data Table</h3>-->
        <!--</mat-panel-title>-->
        <!--</mat-expansion-panel-header>-->
        <!--<mat-nav-list>-->
        <!--<a mat-list-item routerLink="/elements/table" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<h3 matLine>Table</h3>-->
        <!--</a>-->
        <!--<a mat-list-item routerLink="/elements/sortable" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<h3 matLine>Sortable</h3>-->
        <!--</a>-->
        <!--<a mat-list-item routerLink="/elements/paginator" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<h3 matLine>Paginator</h3>-->
        <!--</a>-->
        <!--</mat-nav-list>-->
        <!--</mat-expansion-panel>-->

        <!--&lt;!&ndash; examples menu &ndash;&gt;-->

        <!--<h3 class="portal-sidnav-menu__subheader">Examples</h3>-->
        <!--<mat-expansion-panel>-->
        <!--<mat-expansion-panel-header>-->
        <!--<mat-panel-title>-->
        <!--<mat-icon>loop</mat-icon>-->
        <!--<h3 matLine>RxJS</h3>-->
        <!--</mat-panel-title>-->
        <!--</mat-expansion-panel-header>-->
        <!--<mat-nav-list>-->
        <!--<a mat-list-item routerLink="/rxjs/intro" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<h3 matLine>Introduction</h3>-->
        <!--</a>-->
        <!--<a mat-list-item routerLink="/rxjs/subject" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<h3 matLine>Subject</h3>-->
        <!--</a>-->
        <!--</mat-nav-list>-->
        <!--</mat-expansion-panel>-->

        <!--&lt;!&ndash; portal menu &ndash;&gt;-->

        <!--<h3 class="portal-sidnav-menu__subheader">Portal</h3>-->
        <!--<mat-expansion-panel>-->
        <!--<mat-expansion-panel-header>-->
        <!--<mat-panel-title>-->
        <!--<mat-icon>loop</mat-icon>-->
        <!--<h3 matLine>Portal Examples</h3>-->
        <!--</mat-panel-title>-->
        <!--</mat-expansion-panel-header>-->
        <!--<mat-nav-list>-->
        <!--<a mat-list-item routerLink="/portal-examples/layout-service" routerLinkActive="portal-menu&#45;&#45;active">-->
        <!--<h3 matLine>Layout Service</h3>-->
        <!--</a>-->
        <!--</mat-nav-list>-->
        <!--</mat-expansion-panel>-->
    </div>
</div>
