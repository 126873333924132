import { Injectable } from '@angular/core';
import { Rpc } from '../rpc';
import { Country } from '../models/geo/country';
import { GeocoderAddress } from '../models/geo/geocoder-address';
import { Product } from '../models/internal/product';
import { UserStatType } from '../models/internal/user-stat-type';
import { User } from '@models/internal/user';
import { CompletedTrains } from '../models/internal/completed-trains';
import { Order } from '../models/internal/order';
import { Registrations } from '../models/internal/registrations';
import { Activations } from '../models/internal/activations';
import { OrderInfo } from '../models/internal/orderInfo';
import { UserShort } from '../models/internal/userShort';
import { PaginationList } from '../models/pagination-list';
import { OrderV2 } from '../models/internal/orderV2';
import { UserInfo } from '../models/internal/userInfo';
import { Stock } from '../models/internal/stock';
import { PriceSet } from '../models/internal/priceSet';
import { CoachGroup } from '../models/internal/coachGroup';
import { UserCompany } from '../models/internal/user-company';
import { Subscription } from '../models/internal/subscription';
import { Promocode } from '../models/internal/promocode';
import { Discount } from '../models/internal/discount';
import { Gift } from '../models/internal/gift';
import { ActivatedGift } from '../models/internal/activatedGifts';
import { AnalyticsHistory } from '../models/internal/analyticsHistory';
import { Train } from '../models/internal/train';
import { LogHistory } from '../models/internal/logHistory';
import { UserStat } from '../models/internal/userStat';
import { UserMark } from '../models/internal/userMark';
import { CacheMemoryService } from '../cache';
import { UserNotifications } from '../models/internal/userNotifications';
import { CountryAndCurrency } from '../models/internal/countryAndCurrency';
import { BanHistory } from '../models/internal/banHistory';
import { InstallmentWithOrderInfo } from '../models/internal/installment_with_order_info';
import { Course } from '../models/internal/course';

@Injectable({
    providedIn: 'root',
})
export class InternalApiService {
    constructor(protected rpc: Rpc, private cache: CacheMemoryService) {}

    getCountries(): Promise<Country[]> {
        return this.rpc.call('internal.getCountries');
    }

    getGeocoderAddress(): Promise<GeocoderAddress[]> {
        return this.rpc.call('internal.getGeocoderAddress');
    }

    getUserMarks(): Promise<string[]> {
        return this.rpc.call('internal.getUserMarks');
    }

    async getProducts(cache = false): Promise<Product[]> {
        const cacheKey = 'internal.getProducts';

        if (cache && this.cache.get(cacheKey)) {
            return this.cache.get(cacheKey);
        }

        const products = await this.rpc.call('internal.getProducts');

        if (!products.find(x => x.product === null)) {
            products.push({
                name: 'Без продукта',
                product: null,
                ns: '',
            });
        }

        if (products) {
            this.cache.set(cacheKey, products, 600000);
        }
        return products;
    }

    async getProductName(product: string): Promise<string> {
        const products = await this.getProducts(true);
        let item = products.find(x => x.product === product);
        return item?.name || product;
    }

    getUserStats(): Promise<UserStatType[]> {
        return this.rpc.call('internal.getUserStats');
    }

    getUserById(userId: number): Promise<User | null> {
        return this.rpc.call('internal.getUserById', { userId });
    }

    getRegistration(from: string = 'now', to: string = 'now'): Promise<Registrations[]> {
        return this.rpc.call('internal.statistics.getRegistration', { from: from, to: to });
    }

    getActivation(from: string = 'now', to: string = 'now'): Promise<Activations[]> {
        return this.rpc.call('internal.statistics.getActivation', { from: from, to: to });
    }

    getTrains(from: string = 'now', to: string = 'now'): Promise<CompletedTrains[]> {
        return this.rpc.call('internal.statistics.getTrains', { from: from, to: to });
    }

    getCompletedOrders(from: string = 'now', to: string = 'now'): Promise<Order[]> {
        return this.rpc.call('internal.statistics.getCompletedOrders', { from: from, to: to });
    }

    getOrderByNumber(number: string): Promise<OrderInfo> {
        return this.rpc.call('internal.order.getByNumber', { number });
    }

    getUsersList({
        id,
        email,
        registrationFrom,
        registrationTo,
        firstname,
        lastname,
        pageSize = 100,
        pageIndex = 0,
    }: {
        id?: number;
        email?: string;
        registrationFrom?: string;
        registrationTo?: string;
        firstname?: string;
        lastname?: string;
        pageSize?: number;
        pageIndex?: number;
    }): Promise<PaginationList<UserShort>> {
        return this.rpc.call('internal.user.getAll', {
            id: id || null,
            email: email || null,
            registrationFrom: registrationFrom || null,
            registrationTo: registrationTo || null,
            firstname: firstname || null,
            lastname: lastname || null,
            pageSize: pageSize,
            pageIndex: pageIndex,
        });
    }

    getOrdersList({
        from,
        to,
        userId,
        status,
        regFrom,
        regTo,
        kassa,
        domain,
        orderNumber,
        transactionId,
        product,
        tid,
        wik,
        pik,
        attrScheme,
        pageSize = 100,
        pageIndex = 0,
    }: {
        from?: string;
        to?: string;
        userId?: number;
        status?: string;
        regFrom?: string;
        regTo?: string;
        kassa?: string[];
        domain?: string[];
        orderNumber?: string;
        transactionId?: string;
        product?: string;
        tid?: string;
        wik?: string;
        pik?: string;
        attrScheme?: string;
        pageSize?: number;
        pageIndex?: number;
    }): Promise<PaginationList<OrderV2>> {
        return this.rpc.call('internal.order.getAll', {
            from: from || null,
            to: to || null,
            userId: userId || null,
            status: status || null,
            regFrom: regFrom || null,
            regTo: regTo || null,
            kassa: kassa || null,
            domain: domain || null,
            orderNumber: orderNumber || null,
            transactionId: transactionId || null,
            product: product || null,
            tid: tid || null,
            wik: wik || null,
            pik: pik || null,
            attrScheme: attrScheme || null,
            pageSize: pageSize,
            pageIndex: pageIndex,
        });
    }

    getUserInfoById(userId: number): Promise<UserInfo> {
        return this.rpc.call('internal.user.getById', userId);
    }

    getUserCohorts(userId: number): Promise<string[]> {
        return this.rpc.call('internal.user.getCohorts', userId);
    }

    getUserStock(userId: number): Promise<Stock> {
        return this.rpc.call('internal.user.getStock', userId);
    }

    getUserPriceSet(userId: number): Promise<PriceSet> {
        return this.rpc.call('internal.user.getPriceSet', userId);
    }

    getCoachGroupByUserUd(userId: number): Promise<CoachGroup> {
        return this.rpc.call('internal.user.getCoachGroup', userId);
    }

    getCompanyByUserUd(userId: number): Promise<UserCompany> {
        return this.rpc.call('internal.user.getCompany', userId);
    }

    getUserPhones(userId: number): Promise<string[]> {
        return this.rpc.call('internal.user.getPhones', userId);
    }

    getUresInstallments(userId: number): Promise<Subscription[]> {
        return this.rpc.call('internal.user.getInstallments', userId);
    }

    getUserPromocodes(userId: number): Promise<Promocode[]> {
        return this.rpc.call('internal.user.getPromocodes', userId);
    }

    getUserDiscounts(userId: number): Promise<Discount[]> {
        return this.rpc.call('internal.user.getDiscounts', userId);
    }

    getUserGirts(userId: number): Promise<Gift[]> {
        return this.rpc.call('internal.user.getGifts', userId);
    }

    getActivatedGifts(userId: number): Promise<ActivatedGift[]> {
        return this.rpc.call('internal.user.getActivatedGifts', userId);
    }

    getAutologin(userId: number): Promise<string | null> {
        return this.rpc.call('internal.user.getAutologin', userId);
    }

    getUserAnalyticsHistory(userId: number): Promise<AnalyticsHistory[]> {
        return this.rpc.call('internal.user.getAnalyticsHistory', userId);
    }

    resetCohortsCash(userId: number): Promise<boolean> {
        return this.rpc.call('internal.user.resetCohort', userId);
    }

    sendUserPassword(userId: number): Promise<boolean> {
        return this.rpc.call('internal.user.resendpass', userId);
    }

    sendPromoSupport(userId: number, balance: number): Promise<boolean> {
        return this.rpc.call('internal.user.sendPromoSupport', { userId: userId, balance: balance });
    }

    getUserTrains(userId: number, platform: string): Promise<{ [key: string]: Train | null }> {
        return this.rpc.call('internal.user.getTrains', { userId: userId, platform: platform });
    }

    getUserBalance(userId: number): Promise<number> {
        return this.rpc.call('internal.user.getBalance', userId);
    }

    updateUserBalance(userId: number, balance: number, comment: string): Promise<number> {
        return this.rpc.call('internal.user.updateBalance', { userId: userId, balance: balance, comment: comment });
    }

    getUserLogHistory(userId: number): Promise<LogHistory[]> {
        return this.rpc.call('internal.user.getLogHistory', userId);
    }

    getUserStatsAndMarks(userId: number): Promise<{ stats: UserStat[]; marks: UserMark[] }> {
        return this.rpc.call('internal.user.getStats', userId);
    }

    activatePackageForUser(userId: number, _package: string, comment: string): Promise<boolean> {
        return this.rpc.call('internal.user.activatePackage', { userId: userId, package: _package, comment: comment });
    }

    deactivatePackageForUser(userId: number, _package: string, comment: string): Promise<boolean> {
        return this.rpc.call('internal.user.deactivatePackage', {
            userId: userId,
            package: _package,
            comment: comment,
        });
    }

    deactivateUserOrder(userId: number, orderId: number, comment: string): Promise<boolean> {
        return this.rpc.call('internal.user.deactivateOrder', { userId: userId, orderId: orderId, comment: comment });
    }

    cancelRecurrentById(id: number): Promise<boolean> {
        return this.rpc.call('internal.order.cancelRecurrentById', id);
    }

    cancelInstallmentById(id: number): Promise<boolean> {
        return this.rpc.call('internal.order.cancelInstallmentById', id);
    }

    resignInstallmentById(id: number): Promise<boolean> {
        return this.rpc.call('internal.order.resignInstallmentById', id);
    }

    returnOrderById(id: string, refundPrice: number): Promise<boolean> {
        return this.rpc.call('internal.order.returnById', { id: id, refundPrice: refundPrice });
    }

    refundOrderById(id: string, refundPrice: number): Promise<boolean> {
        return this.rpc.call('internal.order.refundById', { id: id, refundPrice: refundPrice });
    }

    activateOrder(orderId: string, comment: string): Promise<boolean> {
        return this.rpc.call('internal.order.activateOrder', { orderId: orderId, comment: comment });
    }

    setUserCompany(userId: number, companyId: number | null): Promise<boolean> {
        return this.rpc.call('internal.user.setCompany', { userId: userId, companyId: companyId });
    }

    getUserNotification(userId: number): Promise<UserNotifications> {
        return this.rpc.call('internal.user.notification.getSettings', userId);
    }

    updateUserNotification(userId: number, notification: UserNotifications): Promise<boolean> {
        return this.rpc.call('internal.user.notification.updateSettings', {
            userId: userId,
            ...notification,
        });
    }

    getCountriesAndCurrencies(): Promise<CountryAndCurrency[]> {
        return this.rpc.call('internal.country.getAll');
    }

    updateUser({
        userId,
        firstname,
        lastname,
        birthday,
        comment,
        email,
        timeZone,
        locale,
        countryCode,
        preferedCurrency,
        score,
        hasNeuroWidget,
        isBounce,
        hasPremium,
        tarifEndDate,
        hideInRating,
    }: {
        userId: number;
        firstname: string | null;
        lastname: string | null;
        birthday: string | null;
        timeZone: string;
        email: string;
        comment: string;
        locale: string;
        countryCode: string;
        preferedCurrency: string;
        score: number;
        hasNeuroWidget: boolean;
        isBounce: boolean;
        hasPremium: boolean;
        tarifEndDate: string;
        hideInRating: boolean;
    }): Promise<boolean> {
        return this.rpc.call('internal.user.update', {
            userId: userId,
            comment: comment,
            firstname: firstname,
            lastname: lastname,
            email: email,
            locale: locale,
            birthday: birthday,
            timeZone: timeZone,
            countryCode: countryCode,
            preferedCurrency: preferedCurrency,
            score: score,
            hasNeuroWidget: hasNeuroWidget,
            isBounce: isBounce,
            hasPremium: hasPremium,
            tarifEndDate: tarifEndDate,
            hideInRating: hideInRating,
        });
    }

    getUserIsBan(userId: number): Promise<boolean> {
        return this.rpc.call('internal.user.isBan', userId);
    }

    toggleUserBan(userId: number, comment: string, isBan: boolean): Promise<boolean> {
        return this.rpc.call('internal.user.ban', { userId: userId, comment: comment, isBan: isBan });
    }

    getUserBanHistory(userId: number): Promise<BanHistory[]> {
        return this.rpc.call('internal.user.banHistory', userId);
    }

    deleteUser(userId: number): Promise<boolean> {
        return this.rpc.call('internal.user.delete', userId);
    }

    restoreUser(userId: number): Promise<boolean> {
        return this.rpc.call('internal.user.restore', userId);
    }

    getInstallments({
        from,
        to,
        status,
        pageSize,
        pageIndex,
    }: {
        from: string;
        to: string;
        status: string;
        pageSize: number;
        pageIndex: number;
    }): Promise<PaginationList<InstallmentWithOrderInfo>> {
        return this.rpc.call('internal.order.installments', {
            from: from,
            to: to,
            status: status,
            pageSize: pageSize,
            pageIndex: pageIndex,
        });
    }

    getAllCourses(): Promise<Course[]> {
        return this.rpc.call('internal.course.getAll');
    }

    deleteUserMark(markId: number): Promise<boolean> {
        return this.rpc.call('internal.user.mark.delete', markId);
    }

    addUserMark(mark: string, userId: number): Promise<boolean> {
        return this.rpc.call('internal.user.mark.add', { mark: mark, userId: userId });
    }

    addUserStat(slug: string, value: string, userId: number): Promise<boolean> {
        return this.rpc.call('internal.user.stat.add', { userId: userId, slug: slug, value: value });
    }

    updateUserStat(slug: string, value: string, userId: number): Promise<boolean> {
        return this.rpc.call('internal.user.stat.update', { userId: userId, slug: slug, value: value });
    }

    deleteUserStat(slug: string, userId: number): Promise<boolean> {
        return this.rpc.call('internal.user.stat.delete', { userId: userId, slug: slug });
    }
}
