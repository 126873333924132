import { Injectable, Inject } from '@angular/core';
import { AuthService } from '../auth.service';
import { Resolve } from '@angular/router';
import { Admin } from '@app/shared/models/admin';

@Injectable({
    providedIn: 'root',
})
export class AuthUserResolve implements Resolve<any> {
    /**
     * @param auth
     */
    public constructor(@Inject(AuthService) private auth: AuthService) {}

    resolve(): Promise<Admin> {
        return this.auth.getUser();
    }
}
