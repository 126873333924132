import { AuthProviderInterface, AuthAbstractProvider, AuthProviderParamsInterface } from './provider';
import { AuthConnectorInterface } from '../connector/connector';

export class AuthCredentialProvider extends AuthAbstractProvider implements AuthProviderInterface {
    /**
     * @param name
     * @param params
     * @param connector
     */
    constructor(name: string, params: AuthProviderParamsInterface, connector: AuthConnectorInterface) {
        super(name, params, connector);
    }

    /**
     * Authenticate.
     */
    authenticate(params?): Promise<any> {
        return new Promise(resolve => resolve(params || {}));
    }

    /**
     * Connect.
     */
    connect(params?): Promise<any> {
        // Not support, reject always.

        return new Promise((resolve, reject) => reject(false));
    }
}
