import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BannerApiService } from '@app/shared/api/banner/banner-api.service';
import { Banner } from '@models/banner/banner';

@Component({
    selector: 'app-banner-select',
    templateUrl: './banner-select.component.html',
    styleUrls: ['./banner-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: BannerSelectComponent,
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerSelectComponent implements OnInit, ControlValueAccessor {
    @Input() spot: string;
    @Input() disabled = false;
    @Input() typeModel = false;

    onChange: Function;
    banners: Banner[] = [];
    value = null;

    constructor(private bannerApiService: BannerApiService, private changeDetectorRef: ChangeDetectorRef) {}

    async ngOnInit() {
        this.banners = await this.bannerApiService.getAll();
        this.changeDetectorRef.detectChanges();
    }

    selectValue(value: number | null) {
        if (this.onChange) {
            this.onChange(value);
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {}

    setDisabledState(isDisabled: boolean): void {}

    writeValue(value: any): void {
        this.value = value;
        this.changeDetectorRef.detectChanges();
    }

    getBanners(): Banner[] {
        return (this.banners || []).filter(banner => !this.spot || (this.spot && this.spot == banner.spot));
    }

    compare(c1, c2): boolean {
        if (typeof c1 === 'string') {
            return c1 == c2;
        }

        return c2 && c1.id == c2.id;
    }
}
