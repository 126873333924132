<div fxLayout="row" fxLayoutAlign="start baseline">
    <mat-form-field appearance="outline" #formField>
        <mat-label>Период</mat-label>
        <mat-date-range-input [rangePicker]="picker">
            <input matStartDate placeholder="От" [(ngModel)]="ngModel.from" required />
            <input matEndDate placeholder="До" [(ngModel)]="ngModel.to" required />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>

    <button
        style="margin-left: 12px;"
        mat-raised-button
        color="primary"
        (click)="emit()"
        matTooltip="Искать за период"
        [disabled]="formField._control.errorState"
    >
        <mat-icon>search</mat-icon>
    </button>
</div>
