export abstract class Filter<T> {
    name: string;
    value: T | null;
    controlType: string;
    key: string;
    options: { key: string; value: string | number }[];

    constructor(
        name: string,
        options: {
            value?: T;
            options?: { key: string; value: string | number }[];
        } = {},
    ) {
        this.name = name;
        this.value = options.value || null;
        this.options = options.options || [];
    }

    isEmpty(): boolean {
        return this.value === null || this.value === undefined;
    }

    toJson(): { name: string; value: T | null } {
        return {
            name: this.name,
            value: this.value,
        };
    }
}
