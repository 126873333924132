import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { isEqual } from 'lodash';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
    selector: 'app-cohorts-select-from-list',
    templateUrl: './cohorts-select-from-list.component.html',
    styleUrls: ['./cohorts-select-from-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CohortsSelectFromListComponent implements OnChanges {
    @Input() cohorts: string[] = [];
    @Output() updatedSelectedCohorts = new EventEmitter<string[]>();

    dropdownList = [];
    selectedItems = [];
    prevSevectedItems = [];

    dropdownSettings: IDropdownSettings = {
        singleSelection: false,
        idField: 'item_id',
        textField: 'item_text',
        selectAllText: 'Выбрать все',
        unSelectAllText: 'Убрать все',
        searchPlaceholderText: 'Поиск',
        noFilteredDataAvailablePlaceholderText: 'Не найдено',
        allowSearchFilter: true,
        itemsShowLimit: 6,
    };

    constructor(private changeDetection: ChangeDetectorRef) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.cohorts) {
            this.dropdownList = this.cohorts || [];
            this.selectedItems = [...this.dropdownList];
            this.prevSevectedItems = [...this.selectedItems];
            this.changeDetection.detectChanges();
        }
    }

    onDropDownClose() {
        if (!isEqual(this.prevSevectedItems, this.selectedItems)) {
            this.updatedSelectedCohorts.emit(this.selectedItems);
            this.prevSevectedItems = [...this.selectedItems];
        }
    }
}
