import { Filter } from './filter';

export class YesOrNoWithSingleOptionAndValue extends Filter<{
    has: boolean;
    option: string | number;
    value: string | number;
}> {
    controlType = 'yes_or_no_with_single_option_and_value';
    value = { has: null, option: null, value: null };

    isEmpty(): boolean {
        return (
            this.value === null || this.value.has === null || this.value.option === null || this.value.value === null
        );
    }
}
