<mat-form-field>
    <mat-label>{{ label }}</mat-label>
    <mat-chip-list #chipList>
        <mat-chip *ngFor="let tag of selected" [selectable]="true" [removable]="true" (removed)="remove(tag)">
            {{ tag }}
            <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
        </mat-chip>
        <input
            [formControl]="tagCtrl"
            [placeholder]="placeholder"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="true"
            (matChipInputTokenEnd)="add($event)"
        />
    </mat-chip-list>
</mat-form-field>
<mat-error *ngIf="tagCtrl.hasError('pattern')">
    Не правильный формат
    <strong>{{ pattern }}</strong>
</mat-error>
