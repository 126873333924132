import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    direction: string;

    constructor(private router: Router, private title: Title) {
        const sessionDirection = sessionStorage.getItem('portal-direction');
        this.direction = sessionDirection || 'ltr';
    }

    ngOnInit(): void {
        this.router.events.subscribe(evt => {
            if (evt['snapshot'] && evt['snapshot']['data'] && evt['snapshot']['data']) {
                const data = evt['snapshot']['data'];

                // title meta
                if (data['title']) {
                    this.title.setTitle(data['title'] + ' | Wikium.ru');
                }
            }
        });
    }
}
