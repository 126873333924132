import { Injectable } from '@angular/core';
import { Rpc } from '../rpc';
import { Stock } from '@models/stock';
import { PaginationList } from '../models/pagination-list';
import { Media } from '../models/stock/media';
import { Schedule } from '@models/stock/schedule';
import { Timer } from '@models/stock/timer';

@Injectable({
    providedIn: 'root',
})
export class StocksApiService {
    constructor(protected rpc: Rpc) {}

    list(pageIndex = 0, pageSize = 25, search = null): Promise<PaginationList<Stock>> {
        return this.rpc.call('stock.list', {
            pageSize: pageSize,
            pageIndex: pageIndex,
            search: search,
        });
    }

    getAllStocks(): Promise<Stock[]> {
        return this.rpc.call('stock.getAll');
    }

    createStock(name: string, media: Media[], timer: Timer | null): Promise<Stock[]> {
        return this.rpc.call('stock.create', {
            name: name,
            media: media,
            timerId: timer && timer.id,
        });
    }

    updateStock(id: number, name: string, media: Media[], timer: Timer | null) {
        return this.rpc.call('stock.update', {
            id: id,
            name: name,
            media: media,
            timerId: timer && timer.id,
        });
    }

    getStockById(id: number): Promise<Stock> {
        return this.rpc.call('stock.get', { id: id });
    }

    createSchedule(
        stock: Stock | null,
        cohortSlug: string,
        showTo: string,
        showFrom: string,
        priceSetSlug: string,
        priceClass: string,
    ): Promise<Schedule> {
        return this.rpc.call('stock.schedule.create', {
            stockId: stock ? stock.id : null,
            cohortSlug: cohortSlug,
            showTo: showTo,
            showFrom: showFrom,
            priceSetSlug: priceSetSlug,
            priceClass: priceClass,
        });
    }

    updateSchedule(
        id: number,
        stock: Stock | null,
        cohortSlug: string,
        showTo: string,
        showFrom: string,
        priceSetSlug: string,
        priceClass: string,
    ): Promise<Schedule> {
        return this.rpc.call('stock.schedule.update', {
            id: id,
            stockId: stock ? stock.id : null,
            cohortSlug: cohortSlug,
            showTo: showTo,
            showFrom: showFrom,
            priceSetSlug: priceSetSlug,
            priceClass: priceClass,
        });
    }

    getAllSchedules(): Promise<Schedule[]> {
        return this.rpc.call('stock.schedule.getAll');
    }

    changeScheduleState(id: number, isEnabled: boolean): Promise<Schedule> {
        return this.rpc.call('stock.schedule.changeState', {
            id: id,
            isEnabled: isEnabled,
        });
    }

    changeSchedulePosition(id: number, position: number): Promise<Schedule> {
        return this.rpc.call('stock.schedule.changePosition', {
            id: id,
            position: position,
        });
    }

    deleteScheduleState(id: number): Promise<Schedule> {
        return this.rpc.call('stock.schedule.delete', [id]);
    }

    createTimer(name: string, product: string): Promise<Timer> {
        return this.rpc.call('stock.timer.create', {
            name,
            product,
        });
    }

    updateTimer(timer: Timer): Promise<Timer> {
        return this.rpc.call('stock.timer.update', timer);
    }

    getAllTimers(): Promise<Timer[]> {
        return this.rpc.call('stock.timer.getAll');
    }
}
