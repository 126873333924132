<mat-form-field title="{{ currentKey }}">
    <mat-label>{{ label }}</mat-label>

    <input
        type="text"
        matInput
        [matAutocomplete]="auto"
        [formControl]="inputControl"
        [placeholder]="placeholder"
        *ngIf="!selected"
    />

    <input
        *ngIf="selected"
        type="text"
        matInput
        [readonly]="true"
        class="visually-hidden"
        [value]="displayOption(selected)"
    />
    <span *ngIf="selected">{{ displayOption(selected) }}</span>

    <mat-autocomplete (optionSelected)="optionSelected($event)" #auto="matAutocomplete">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option.value">
            {{ option.key }}
        </mat-option>
    </mat-autocomplete>

    <button mat-icon-button matSuffix (click)="clear()" *ngIf="inputControl.value">
        <mat-icon>clear</mat-icon>
    </button>
</mat-form-field>
