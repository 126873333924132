import { Injectable } from '@angular/core';
import { CacheMemoryService } from '../cache';
import { Game } from '../models/game/game';
import { Rpc } from '../rpc';

@Injectable({
    providedIn: 'root',
})
export class GameInternalApiServise {
    constructor(protected rpc: Rpc, private cache: CacheMemoryService) {}

    async getAll(cache = false): Promise<Game[]> {
        const cacheKey = 'internal.game.getAll';

        if (cache && this.cache.get(cacheKey)) {
            return this.cache.get(cacheKey);
        }

        const games = await this.rpc.call('internal.game.getAll');

        if (games) {
            this.cache.set(cacheKey, games, 600000);
        }
        return games;
    }

    getById(gameId: number): Promise<Game> {
        return this.rpc.call('internal.game.getById', gameId);
    }

    create({
        name,
        gameKey,
        skill,
        courseId,
        humanSkills,
        gameType,
        isNew,
        isDuelReady,
        inTrain,
        gameMode,
        time,
        platform,
        locales,
        tariffs,
    }: {
        name: string;
        gameKey: string;
        skill: string;
        courseId: number | null;
        humanSkills: string[] | null;
        gameType: string;
        isNew: boolean;
        isDuelReady: boolean;
        inTrain: boolean;
        gameMode: string;
        time: number | null;
        platform: string;
        locales: string[];
        tariffs: string[];
    }): Promise<boolean> {
        return this.rpc.call('internal.game.create', {
            name: name,
            gameKey: gameKey,
            skill: skill,
            courseId: courseId,
            humanSkills: humanSkills,
            gameType: gameType,
            isNew: isNew,
            isDuelReady: isDuelReady,
            inTrain: inTrain,
            gameMode: gameMode,
            time: time,
            platform: platform,
            locales: locales,
            tariffs: tariffs,
        });
    }

    update(game: Game): Promise<boolean> {
        return this.rpc.call('internal.game.update', {
            gameId: game.id,
            name: game.name,
            gameKey: game.gameKey,
            skill: game.skill,
            courseId: game.courseId,
            humanSkills: game.humanSkills,
            gameType: game.gameType,
            isNew: game.isNew,
            isDuelReady: game.isDuelReady,
            inTrain: game.inTrain,
            gameMode: game.gameMode,
            time: game.time,
            platform: game.platform,
            locales: game.locales,
            tariffs: game.tariffs,
        });
    }
}
