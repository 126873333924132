<mat-form-field>
    <mat-label>{{ label }}</mat-label>
    <mat-chip-list #tagsList>
        <mat-chip
            *ngFor="let option of selectedOptions"
            [selectable]="false"
            [removable]="true"
            (removed)="remove(option.value)"
        >
            {{ option.key }}
            <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input
            #tagInput
            [placeholder]="placeholder"
            [formControl]="tagCtrl"
            [matAutocomplete]="auto"
            [matChipInputFor]="tagsList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        />
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event)">
        <mat-option *ngFor="let option of notSelectedOptions | async" [value]="option.value">
            {{ option.key }}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
