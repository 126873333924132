<div
    class="stock-media"
    *ngFor="let item of media; index as i"
    fxLayout="row"
    fxLayoutAlign="space-between baseline"
    fxFlex="100%"
>
    <ng-container *ngIf="item.type === 'banner'">
        <app-banner-spot-select [(ngModel)]="item.spot" fxFlex="45%"></app-banner-spot-select>
        <app-banner-select [(ngModel)]="item.slug" fxFlex="45%" [spot]="item.spot"></app-banner-select>
        <button button mat-mini-fab color="primary" matTooltip="Удалить" (click)="deleteMedia(media, i)">
            <mat-icon>delete_forever</mat-icon>
        </button>
    </ng-container>
</div>

<button mat-fab color="primary" [matMenuTriggerFor]="menu" aria-label="Выбрать тип медиа">
    <mat-icon>post_add</mat-icon>
</button>
<mat-menu #menu="matMenu">
    <button mat-menu-item (click)="addMedia('banner')">
        <mat-icon>picture_in_picture</mat-icon>
        <span>Баннеры</span>
    </button>
    <!--    <button mat-menu-item disabled>-->
    <!--        <mat-icon>waves</mat-icon>-->
    <!--        <span>Ленты</span>-->
    <!--    </button>-->
    <!--    <button mat-menu-item disabled>-->
    <!--        <mat-icon>chat_bubble_outline</mat-icon>-->
    <!--        <span>Попапы</span>-->
    <!--    </button>-->
</mat-menu>
