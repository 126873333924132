<div
    *ngIf="layoutService.getLoader() | async"
    class="portal-layout-loader"
    fxLayout="column"
    fxLayoutAlign="center center"
>
    <div class="waiter__spinner">
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>
