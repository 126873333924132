import { ChangeDetectorRef, Component, ElementRef, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { map, startWith, tap } from 'rxjs/operators';
import { EventEmitter } from '@angular/core';

@Component({
    selector: 'app-auto-select',
    templateUrl: './auto-select.component.html',
    styleUrls: ['./auto-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AutoSelectComponent),
            multi: true,
        },
    ],
})
export class AutoSelectComponent implements OnInit, ControlValueAccessor {
    @Input() label: string;
    @Input() placeholder: string = 'type...';
    @Input() options: { key: string; value: string }[];
    @Output() valueChange = new EventEmitter<number | string | null>();

    _onChange: (value: number | string | null) => void;

    selected: number | string | null = null;
    filteredOptions: Observable<{ key: string; value: number | string }[]>;
    inputControl = new UntypedFormControl();

    constructor(private cd: ChangeDetectorRef) {
        this.filteredOptions = this.inputControl.valueChanges.pipe(
            startWith(''),
            map((val: number | string | null) => {
                if (!val) {
                    return this.options;
                }

                return this.options.filter(
                    f =>
                        (f.value + '').toLowerCase().includes((val + '').toLowerCase()) ||
                        f.key.toLowerCase().includes((val + '').toLowerCase()),
                );
            }),
            tap(_ => this.cd.markForCheck()),
        );
    }

    async ngOnInit() {}

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {}

    writeValue(value: string): void {
        this.selected = value;
        this.inputControl.setValue(this.selected);
    }

    optionSelected($event) {
        this.selected = $event.option.value;
        this.valueChange.emit(this.selected);
        this._onChange(this.selected);
    }

    displayOption(value: string | number) {
        return this.options.find(o => o.value == value).key;
    }

    get currentKey() {
        if (!this.selected) {
            return null;
        }

        return this.displayOption(this.selected);
    }

    clear() {
        this.selected = null;
        this._onChange(this.selected);
        this.valueChange.emit(this.selected);
        this.inputControl.setValue(null);
    }
}
