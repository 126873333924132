import { Injectable } from '@angular/core';
import { Rpc } from '@app/shared/rpc';
import { Cohort } from '@models/cohort';
import { PaginationList } from '../models/pagination-list';

@Injectable({
    providedIn: 'root',
})
export class CohortApiService {
    constructor(protected rpc: Rpc) {}

    getAll(): Promise<{ [key: string]: string }> {
        return this.rpc.call('cohort.getAll', { order: 'desc' });
    }

    getCount(slug: string): Promise<number> {
        return this.rpc.call('cohort.getCount', { slug: slug });
    }

    getBySlug(slug: string): Promise<Cohort> {
        return this.rpc.call('cohort.getBySlug', { slug: slug });
    }

    list(
        pageIndex: number,
        pageSize: number,
        search: string,
        onlyPublic: boolean,
    ): Promise<PaginationList<Omit<Cohort, 'filter'>>> {
        return this.rpc.call('cohort.list', { pageIndex, pageSize, search, onlyPublic });
    }

    get(id: number): Promise<Cohort> {
        return this.rpc.call('cohort.get', { id });
    }

    create(input: Omit<Cohort, 'id'>): Promise<Cohort> {
        return this.rpc.call('cohort.create', input);
    }

    update(input: Omit<Cohort, 'slug'>): Promise<Cohort> {
        return this.rpc.call('cohort.update', input);
    }
}
