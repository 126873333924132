import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-text-tags-select',
    templateUrl: './text-tags-select.component.html',
    styleUrls: ['./text-tags-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TextTagsSelectComponent),
            multi: true,
        },
    ],
})
export class TextTagsSelectComponent implements ControlValueAccessor, OnInit {
    separatorKeysCodes: number[] = [ENTER, COMMA];
    tagCtrl = new UntypedFormControl();
    selected: string[] = ['Lemon'];

    _onChange: (selected: string[] | null) => void;
    _onTouched = () => {};

    @Input() placeholder: string = 'type...';
    @Input() label: string;
    @Input() pattern: RegExp;

    @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;

    constructor() {}

    add(event: MatChipInputEvent): void {
        if (this.tagCtrl.invalid) {
            return;
        }
        const value = event.value;

        if ((value || '').trim()) {
            this.selected.push(value.trim());
        }

        this.tagCtrl.setValue(null);
    }

    remove(tag: string): void {
        const index = this.selected.indexOf(tag);

        if (index >= 0) {
            this.selected.splice(index, 1);
        }
    }

    ngOnInit() {
        if (this.pattern) {
            this.tagCtrl.setValidators(Validators.pattern(this.pattern));
        }
    }

    writeValue(value: string[]) {
        this.selected = value || [];
        this.tagCtrl.setValue(null);
    }

    registerOnChange(fn) {
        this._onChange = fn;
    }

    registerOnTouched(fn) {
        this._onTouched = fn;
    }
}
