import { Filter } from './filter/filter';
import { NodeType } from './node-type';

export class Node {
    children: Node[] = [];
    filters: Filter<any>[] = [];

    constructor(public type: NodeType) {}

    isEmpty(): boolean {
        let empty = true;

        this.children.forEach(child => {
            if (!child.isEmpty()) {
                empty = false;
            }
        });
        this.filters.forEach(filter => {
            if (!filter.isEmpty()) {
                empty = false;
            }
        });

        return empty;
    }

    /**
     * Json representation of the node.
     * Does not contains empty child nodes of empty filters.
     */
    toJson(): any {
        return {
            type: this.type,
            children: this.children.filter(c => !c.isEmpty()).map(c => c.toJson()),
            filters: this.filters.filter(f => !f.isEmpty()).map(f => f.toJson()),
        };
    }
}
