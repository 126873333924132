import { Injectable } from '@angular/core';
import { Rpc } from '@app/shared/rpc';
import { PriceSet } from '@models/price/priceSet';
import { Products } from '@models/products';

@Injectable({
    providedIn: 'root',
})
export class PriceApiService {
    constructor(protected rpc: Rpc) {}

    async getPriceSet(): Promise<PriceSet[]> {
        return this.rpc.call('price.getPriceSet');
    }

    async getAllProducts(): Promise<Products> {
        return this.rpc.call('price.getAllProducts');
    }
}
