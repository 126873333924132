import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
} from '@angular/forms';
import { findIndex } from 'lodash';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { InternalApiService } from '@app/shared/api/internal-api.service';
import { Product } from '@models/internal/product';

@Component({
    selector: 'app-product-select',
    templateUrl: './product-select.component.html',
    styleUrls: ['./product-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ProductSelectComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: ProductSelectComponent,
            multi: true,
        },
    ],
})
export class ProductSelectComponent implements OnInit, ControlValueAccessor, Validator {
    products: Product[] = [];
    tariff: string | null = null;
    filteredOptions: Observable<Product[]>;
    inputControl: FormControl<string | null> = new FormControl();
    onChange: Function;

    @Input()
    required = true;

    @Input()
    ns = 'packages';

    constructor(private internalApiService: InternalApiService) {}

    async ngOnInit() {
        this.products = await this.internalApiService.getProducts();

        const validators = [];
        validators.push((control: AbstractControl): ValidationErrors | null => {
            if (!control.value && this.required) {
                return {
                    required: true,
                };
            }

            return findIndex(this.products, product => product.product == control.value) > -1
                ? null
                : { notFound: true };
        });

        this.inputControl.setValidators(validators);

        this.filteredOptions = this.inputControl.valueChanges.pipe(
            startWith(''),
            map((value: string) => this.filterTariff(value)),
        );
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {}

    writeValue(val: any): void {
        this.inputControl.setValue(val);
    }

    filterTariff(value: string): Product[] {
        const filteredValue = (value || '').toLowerCase();
        return this.products.filter((p: Product) => {
            return (
                p.ns == this.ns &&
                (filteredValue == '' ||
                    p.product.toLowerCase().indexOf(filteredValue) > -1 ||
                    p.name.toLowerCase().indexOf(filteredValue) > -1)
            );
        });
    }

    onOptionSelect($event) {
        this.writeValue($event.option.value);

        if (this.onChange) {
            this.onChange($event.option.value);
        }
    }

    validate(control: FormControl): ValidationErrors | null {
        if (!this.inputControl.errors) {
            return null;
        }

        return {
            invalid: true,
        };
    }
}
