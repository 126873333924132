import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '@app/shared/auth';
import { Admin } from '@app/shared/models/admin';

@Component({
    selector: 'portal-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {
    user: Admin;

    constructor(private authService: AuthService) {}

    ngOnInit(): void {
        this.authService.getUser().then(user => {
            this.user = user;
        });
    }
}
