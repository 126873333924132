import { AuthConnectorInterface } from './connector';
import { Rpc } from '@app/shared/rpc';
import { CacheMemoryService } from '@app/shared/cache';
import { Admin } from '@models/admin';

export class AuthRpcConnector implements AuthConnectorInterface {
    constructor(private rpc: Rpc, private cache: CacheMemoryService) {}

    authenticate(name: string, params: any): Promise<any> {
        return this.rpc.call('auth.' + name, params);
    }

    async getUser(cache: boolean): Promise<Admin> {
        const cacheKey = 'auth.user';

        if (cache && this.cache.get(cacheKey)) {
            return this.cache.get(cacheKey);
        }

        try {
            const user = await this.rpc.call('auth.user');

            if (user) {
                this.cache.set(cacheKey, user, 3600);
            }

            return user;
        } catch (error) {
            return null;
        }
    }
}
