<iframe
    [srcdoc]="html | safe: 'html'"
    frameBorder="0"
    class="preview preview--{{ media }} scale-{{ scale }}"
    [style.height.px]="iframeHeight"
    #iframe
    *ngIf="media !== 'responsive'"
></iframe>

<div *ngIf="media === 'responsive'">
    <div class="device-width">
        Адаптивная ширина девайса:
        <span class="device-width__val">{{ currentWidth }}</span>
    </div>

    <div class="responsive" [style.width]="currentWidth" [style.height.px]="iframeHeight" (resized)="onResized($event)">
        <iframe
            [srcdoc]="html | safe: 'html'"
            frameBorder="0"
            class="preview preview--responsive scale-{{ scale }}"
            [style.height.px]="iframeHeight"
            #iframe
        ></iframe>
    </div>

    <mat-form-field class="content__form-field" appearance="outline">
        <mat-label>Выберите девайс:</mat-label>
        <mat-select [value]="currentDevice.name" (selectionChange)="selectDevice($event.value)">
            <mat-option *ngFor="let device of devices" [value]="device.name">
                {{ device.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
