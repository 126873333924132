<div fxLayout="column" fxLayoutAlign="center center">
    <h1 mat-dialog-title>
        Подверждение
    </h1>

    <mat-dialog-content>
        {{ message }}
    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-flat-button [mat-dialog-close]="true" color="warn">Да</button>
        <button mat-flat-button [mat-dialog-close]="false">Отмена</button>
    </mat-dialog-actions>
</div>
