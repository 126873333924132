import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { File as UplodedFile } from '../models/file';
import { AuthService } from '../auth';

@Injectable({
    providedIn: 'root',
})
export class FileManager {
    constructor(private http: HttpClient, private authService: AuthService) {}

    upload(file: File, path?: string) {
        let formData = new FormData();
        formData.append('randomize', '1');
        formData.append('path', path || '');
        formData.append('files[]', file);

        return this.http
            .post<UplodedFile[]>(environment.uploadServer + '/upload', formData, {
                headers: {
                    Authorization: 'Bearer ' + this.authService.getToken(),
                },
            })
            .pipe(
                map(files => {
                    return files[0];
                }),
            );
    }
}
