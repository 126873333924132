import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'portal-horizontal-menu',
    templateUrl: './horizontal-menu.component.html',
    styleUrls: ['./horizontal-menu.component.scss'],
})
export class HorizontalMenuComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
