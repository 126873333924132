<mat-form-field appearance="fill">
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <textarea
        [disabled]="isDisabled"
        (change)="onChange()"
        matInput
        [(ngModel)]="rawValue"
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="10"
    ></textarea>
</mat-form-field>
