import { Directive, Input, OnChanges, OnInit, SimpleChange, ViewContainerRef } from '@angular/core';
import { waitAndBlock } from '@app/shared/waiter';

@Directive({
    selector: '[appWaitAndBlock]',
})
export class WaitAndBlockDirective implements OnInit, OnChanges {
    @Input('appWaitAndBlock')
    wait: boolean;

    resolve;

    constructor(private container: ViewContainerRef) {}

    ngOnChanges(changes: { [property: string]: SimpleChange }) {
        if (this.resolve && !changes.wait.currentValue) {
            this.resolve();
            this.resolve = undefined;
        } else if (!this.resolve && changes.wait.currentValue) {
            waitAndBlock(
                this.container.element.nativeElement,
                new Promise(resolve => {
                    this.resolve = resolve;
                }),
            );
        }
    }

    ngOnInit(): void {
        this.container.clear();
    }
}
