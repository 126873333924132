import { StorageAdapterInterface } from './storage.interface';
import { data } from '@env/local/rpc';

export class StorageAdapterLocal implements StorageAdapterInterface {
    /**
     * Set or update item from storage.
     *
     * @param keyName
     * @param value
     */
    setItem(keyName: string, value: any): void {
        window.localStorage.setItem(keyName, JSON.stringify(value));
    }

    /**
     * Get item from storage.
     *
     * @param keyName
     */
    getItem(keyName: string): any | null {
        const raw = window.localStorage.getItem(keyName);

        try {
            return JSON.parse(raw);
        } catch (error) {
            return null;
        }
    }

    /**
     * Remove item from storage.
     *
     * @param keyName
     */
    removeItem(keyName: string): void {
        window.localStorage.removeItem(keyName);
    }

    /**
     * Clear all in storage.
     */
    clear(): void {
        window.localStorage.clear();
    }

    /**
     * Is storage support.
     */
    isSupport(): boolean {
        return window['localStorage'] !== null;
    }
}
