import { Injectable } from '@angular/core';
import { CacheItem } from './item';
import { CacheInterface } from './cache.interface';
import { CacheAbstract } from './abstract.cache';
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root',
})
export class CacheMemoryService extends CacheAbstract implements CacheInterface {
    private cache: { [kye: string]: CacheItem } = {};

    constructor() {
        super(environment.cache.memory);
    }

    /**
     * Set or update cache key.
     *
     * @param key
     * @param value
     * @param lifetime in sec
     */
    set(key: string, value: any, lifetime: number | null = null): void {
        lifetime = lifetime || this.getOptions()['lifetime'];
        this.cache[key] = new CacheItem(key, value, lifetime * 1000); // in msec
    }

    /**
     * Get from cache.
     *
     * @param key
     * @param def
     */
    get(key: string, def?: any): any | null {
        const item = this.cache[key];

        if (!item) {
            return def;
        }

        if (!item.isValid()) {
            this.remove(key);
            return def;
        }

        return item.getValue();
    }

    /**
     * Remove from cache.
     */
    remove(key: string): void {
        delete this.cache[key];
    }

    /**
     * Clear all in cache.
     */
    clear(): void {
        this.cache = {};
    }

    /**
     * Is key exist.
     */
    has(key: string): boolean {
        if (!key) {
            return false;
        }

        const item = this.cache[key];

        if (!item) {
            return false;
        }

        if (!item.isValid()) {
            this.remove(key);
            return false;
        }

        return true;
    }
}
