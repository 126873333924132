import { Filter } from './filter';

export class YesOrNoWithList extends Filter<{ has: boolean; options: string[] }> {
    controlType = 'yes_or_no_with_list';
    value = { has: null, options: null };

    isEmpty(): boolean {
        return (
            this.value === null ||
            this.value.has === null ||
            this.value.options === null ||
            this.value.options.length === 0
        );
    }
}
