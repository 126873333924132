<mat-form-field appearance="outline" class="select">
    <mat-label>Компании</mat-label>
    <mat-select
        [(ngModel)]="value"
        (selectionChange)="onOptionSelect($event.value)"
        name="company"
        [required]="required"
    >
        <mat-option [value]="null">
            Без компании
        </mat-option>
        <mat-option *ngFor="let item of companies" [value]="+item.id">
            {{ item.name }}
        </mat-option>
    </mat-select>
</mat-form-field>
