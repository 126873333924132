import { Injectable, Inject } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthSecurity } from '../security.service';

@Injectable({
    providedIn: 'root',
})
export class AuthAnonymousActivate implements CanActivate {
    constructor(@Inject(Router) private router: Router, @Inject(AuthSecurity) private security: AuthSecurity) {}

    async canActivate(): Promise<boolean> {
        const isGrant = await this.security.isGrant('ROLE_ANONYMOUS');

        if (!isGrant) {
            this.router.navigate(['/dashboard']);
        }

        return isGrant;
    }
}
