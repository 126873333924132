import { AuthConnectorInterface } from '../connector/connector';

export interface AuthProviderParamsInterface {}

export interface AuthProviderInterface {
    /**
     * Authenticate.
     *
     * @param params (optional)
     */
    authenticate(params?): Promise<any>;

    /**
     * Connect.
     *
     * @param params (optional)
     */
    connect(params?): Promise<any>;

    /**
     * Get provider name.
     */
    getName(): string;

    /**
     * Get provider params.
     */
    getParams(): any;
}

export abstract class AuthAbstractProvider {
    /**
     * Provider name.
     */
    protected name: string;

    /**
     * Auth connector.
     */
    protected connector: AuthConnectorInterface;

    /**
     * Provider params;
     */
    protected params: AuthProviderParamsInterface;

    /**
     * @param name
     * @param params
     * @param connector
     */
    constructor(name: string, params: AuthProviderParamsInterface, connector: AuthConnectorInterface) {
        this.name = name;
        this.params = params;
        this.connector = connector;
    }

    /**
     * Get provider name.
     */
    getName(): string {
        return this.name;
    }

    /**
     * Get provider params.
     */
    getParams(): any {
        return this.params;
    }
}
