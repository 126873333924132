import { Component, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { StocksApiService } from '@app/shared/api/stocks-api.service';
import { Timer } from '@models/stock/timer';

@Component({
    selector: 'app-timer-select',
    templateUrl: './timer-select.component.html',
    styleUrls: ['./timer-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: TimerSelectComponent,
            multi: true,
        },
    ],
})
export class TimerSelectComponent implements OnInit, ControlValueAccessor {
    onChange: (timer: Timer | null) => void;
    allTimers: Timer[] = [];
    timer = null;

    constructor(private stockApiService: StocksApiService) {}

    async ngOnInit() {
        this.allTimers = await this.stockApiService.getAllTimers();
    }

    selectValue(timer: Timer | null) {
        if (this.onChange) {
            this.onChange(timer);
        }
    }

    registerOnChange(fn: (timer: Timer | null) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {}

    setDisabledState(isDisabled: boolean): void {}

    writeValue(timer: Timer | null): void {
        this.timer = timer;
    }

    compare(o1: Timer, o2: Timer) {
        return o2 && o2.id == o1.id;
    }
}
