<quill-editor
    class="editor"
    [(ngModel)]="content"
    (onEditorChanged)="selectValue($event)"
    (onContentChanged)="test($event)"
    [required]="required"
    [styles]="styles"
    [preserveWhitespace]="true"
    [modules]="editorOptions"
></quill-editor>
<mat-error *ngIf="!content && required">Это поле обязательно для заполнения</mat-error>
