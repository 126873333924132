<mat-form-field class="banner-type-select" appearance="outline">
    <mat-label>
        Тип коллекции баннеров
    </mat-label>
    <mat-select
        [(value)]="value"
        (selectionChange)="selectValue($event.value)"
        [disabled]="disabled"
        [compareWith]="compare"
    >
        <mat-option [value]="null">Не выбрано</mat-option>
        <mat-option *ngFor="let type of types" [value]="type">
            {{ type.name }}
        </mat-option>
    </mat-select>
</mat-form-field>
