import { Injectable, Inject } from '@angular/core';
import { AuthService } from '../auth.service';
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root',
})
export class AuthSecurity {
    /**
     * @param auth
     */
    constructor(@Inject(AuthService) private auth: AuthService) {}

    /**
     * Check is user grant to role.
     */
    async isGrant(required: string | string[]): Promise<boolean> {
        const roles = await this.getRoles();
        if (!roles.length || !required) {
            return false;
        }

        if (typeof required === 'string') {
            required = [required];
        }

        return roles.filter(role => (required as string[]).includes(role)).length > 0;
    }

    /**
     * Get roles.
     */
    getRoles(): Promise<string[]> {
        return this.auth
            .getUser()
            .then(user => {
                return user.roles || environment.security.roles.user;
            })
            .catch(() => {
                return environment.security.roles.anonymous;
            });
    }
}
