<mat-form-field appearance="outline">
    <mat-label>{{ label }}</mat-label>
    <input matInput [matDatepicker]="dp" [formControl]="date" required/>
    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
    <mat-datepicker
        #dp
        startView="multi-year"
        (yearSelected)="chosenYearHandler($event)"
        (monthSelected)="chosenMonthHandler($event, dp)"
    ></mat-datepicker>
</mat-form-field>
