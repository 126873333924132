<mat-sidenav-container [dir]="layoutService.getDirection() | async" fxFlexFill>
    <!-- left side navigation -->
    <mat-sidenav #leftSidenav class="portal-classic-leftsidenav" [mode]="leftSidenavMode" [(opened)]="leftSidenavOpen">
        <portal-menu-sidenav class="portal-sidenav-theme" [sidenav]="leftSidenav"></portal-menu-sidenav>
    </mat-sidenav>

    <!-- right side navigation -->
    <mat-sidenav #rightSidenav opened="false" position="end" class="portal-classic-rightsidenav">
        <portal-notification-sidenav [sidenav]="rightSidenav"></portal-notification-sidenav>
    </mat-sidenav>

    <!-- main content -->
    <div fxLayout="column" fxFlexFill>
        <!-- top toolbar -->
        <portal-top-horizontal-menu
            [leftSidenav]="leftSidenav"
            [rightSidenav]="rightSidenav"
        ></portal-top-horizontal-menu>
        <!-- page content -->
        <div fxLayout="column" fxFlex="nogrow noshrink" class="portal-classic-content-wrapper portal-content-bg">
            <div fxFlexFill class="portal-classic-content" portalScrollTop>
                <router-outlet></router-outlet>
                <portal-layout-loader></portal-layout-loader>
            </div>
        </div>
    </div>
</mat-sidenav-container>
