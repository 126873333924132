export function wait(element: HTMLElement, promise: Promise<any>): Promise<any> {
    const waiter = document.createElement('div');
    waiter.classList.add('waiter');

    const waiterSpinner = document.createElement('div');
    waiterSpinner.classList.add('waiter__spinner');
    waiterSpinner.appendChild(document.createElement('div'));
    waiterSpinner.appendChild(document.createElement('div'));
    waiterSpinner.appendChild(document.createElement('div'));

    waiter.appendChild(waiterSpinner);

    element.appendChild(waiter);
    element.classList.add('waited');

    promise.then(() => {
        element.classList.remove('waited');
        waiter.remove();
    });

    return promise;
}
