import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Order } from '@app/shared/models/internal/order';
import { OrderV2 } from '@app/shared/models/internal/orderV2';
import { PageEvent } from '@angular/material/paginator';
import { InternalApiService } from '@app/shared/api/internal-api.service';
import { MatTableDataSource } from '@angular/material/table';
import { XlsxManagerService } from '@app/shared/service/xlsx-manager.service';
@Component({
    selector: 'app-orders-table',
    templateUrl: './orders-table.component.html',
    styleUrls: ['./orders-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrdersTableComponent implements OnChanges {
    @Input() orders: Order[] | OrderV2[];

    @Input() pageIndex: number | null;
    @Input() pageSize: number | null;
    @Input() length: number | null;
    @Input() pageSizeOptions: number[] | null;

    @Input() isEnableExcelExport = false;

    @Output() onPage: EventEmitter<PageEvent> = new EventEmitter();

    dataSource = new MatTableDataSource<{}>([]);

    displayedColumns: string[] = [
        'number',
        'userId',
        'createdAt',
        'completedAt',
        'price',
        'product',
        'paymentName',
        'platform',
    ];

    isWaiting = true;

    constructor(
        private internalApiService: InternalApiService,
        private changeDetectorRef: ChangeDetectorRef,
        private xlsxManagerService: XlsxManagerService,
    ) {}

    async ngOnChanges(changes: SimpleChanges) {
        if ((this.orders || []).length !== 0 && changes.orders) {
            this.isWaiting = true;
            this.dataSource.data = await this.createTableData(this.orders);

            this.isWaiting = false;
            this.changeDetectorRef.detectChanges();
        }
    }

    async createTableData(orders: Order[] | OrderV2[]) {
        const data = [];

        for (let i = 0; i < orders.length; i++) {
            let name = await this.internalApiService.getProductName(orders[i].singleProduct);
            data.push({ ...orders[i], productName: name });
        }

        return data;
    }

    emitPageEvent(event: PageEvent) {
        this.onPage.emit(event);
    }

    exportToExcel() {
        const headers = {
            id: 'ID заказа',
            userId: 'ID пользователя',
            number: 'Номер заказа',
            price: 'Цена',
            'orderData.withdraw_from_balance': 'C баланса',
            'discount.promocode': 'Промокод',
            createdAt: 'Дата создания',
            completedAt: 'Дата завершения',
            productName: 'Продукт',
            description: 'Описание',
        };
        this.xlsxManagerService.exportArrayToExcelWithCustomColumns(this.dataSource.data, headers, 'orders');
    }
}
