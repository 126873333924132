import { Injectable } from '@angular/core';
import { Rpc } from '@app/shared/rpc';
import { Page } from '@models/tilda/page';

@Injectable({
    providedIn: 'root',
})
export class TildaApiService {
    constructor(protected rpc: Rpc) {}

    async getAllPage(): Promise<Page[]> {
        return this.rpc.call('tilda.getAllPage');
    }

    async getPageById(pageId: number): Promise<Page> {
        return this.rpc.call('tilda.getPageById', [pageId]);
    }
}
