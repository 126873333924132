import { Injectable } from '@angular/core';
import { CacheMemoryService } from '../cache';
import { Ability } from '../models/competence/ability';
import { AbilityFactor } from '../models/competence/ability-factor';
import { ShortCompetence } from '../models/competence/short-competence';
import { CourseAbility } from '../models/competence/course-ability';
import { Rpc } from '../rpc';
import { Competence } from '../models/competence/competence';

@Injectable({
    providedIn: 'root',
})
export class CompetenceInternalApiService {
    constructor(private rpc: Rpc, private cache: CacheMemoryService) {}

    async getAllAbilities(cache = false): Promise<Ability[]> {
        const cacheKey = 'internal.competence.getAllAbilities';

        if (cache && this.cache.get(cacheKey)) {
            return this.cache.get(cacheKey);
        }

        const abilities = await this.rpc.call('internal.competence.getAllAbilities');

        if (abilities) {
            this.cache.set(cacheKey, abilities, 600000);
        }
        return abilities;
    }

    getAbilityFactor(abilityId: number): Promise<AbilityFactor[]> {
        return this.rpc.call('internal.competence.getAbilityFactor', abilityId);
    }

    updateAbilityFactor(abilityId: number, games: { [key: string]: number }): Promise<AbilityFactor[]> {
        return this.rpc.call('internal.competence.updateAbilityFactor', { abilityId: abilityId, games: games });
    }

    getAllCoursesAbility(): Promise<CourseAbility[]> {
        return this.rpc.call('internal.competence.getAllCoursesAbility');
    }

    updateCourseAbility(abilityId: number, courseId: number): Promise<boolean> {
        return this.rpc.call('internal.competence.updateCourseAbility', { abilityId: abilityId, courseId: courseId });
    }

    getAllCompetence(): Promise<ShortCompetence[]> {
        return this.rpc.call('internal.competence.getAll');
    }

    createCompetence(
        name: string,
        description: string | null,
        maxBpi: number | null,
        type: string | null,
        group: string | null,
        gamesIds: number[],
    ): Promise<boolean> {
        return this.rpc.call('internal.competence.create', { name, description, maxBpi, type, group, gamesIds });
    }

    updateCompetence(
        competenceId: number,
        name: string,
        description: string | null,
        maxBpi: number | null,
        type: string | null,
        group: string | null,
        gamesIds: number[],
    ): Promise<boolean> {
        return this.rpc.call('internal.competence.update', {
            competenceId,
            name,
            description,
            maxBpi,
            type,
            group,
            gamesIds,
        });
    }

    getCompetenceById(competenceId: number): Promise<Competence> {
        return this.rpc.call('internal.competence.getById', competenceId);
    }

    createCompetenceAbilityGoal(competenceId: number, abilityId: number, goal: number): Promise<boolean> {
        return this.rpc.call('internal.competence.createAbilityGoal', { competenceId, abilityId, goal });
    }

    deleteCompetenceAbilityGoal(abilityGoalId: number): Promise<boolean> {
        return this.rpc.call('internal.competence.deleteAbilityGoal', abilityGoalId);
    }

    updateCompetenceAbilityGoal(id: number, competenceId: number, abilityId: number, goal: number): Promise<boolean> {
        return this.rpc.call('internal.competence.updateAbilityGoal', {
            abilityGoalId: id,
            competenceId,
            abilityId,
            goal,
        });
    }
}
