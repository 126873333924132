import { Injectable } from '@angular/core';
import { Rpc } from '../rpc/index';
import { TokenSubject } from './observable/token.observable';
import { AuthProviderInterface, AuthProviderParamsInterface } from './provider/provider';
import { AuthConnectorInterface } from './connector/connector';
import { AuthRpcConnector } from './connector/rpc.connector';
import { Storage } from '../storage/index';
import { Router } from '@angular/router';
import { AuthCredentialProvider } from './provider/credential.provider';
import { environment } from '@env/environment';
import { Admin } from '@app/shared/models/admin';
import { CacheMemoryService } from '@app/shared/cache';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private providers: AuthProviderInterface[] = [];
    private readonly connector: AuthConnectorInterface;

    constructor(
        private router: Router,
        private storage: Storage,
        private rpc: Rpc,
        private authTokenObservable: TokenSubject,
        private cache: CacheMemoryService,
    ) {
        // Set auth connector.
        this.connector = new AuthRpcConnector(rpc, cache);

        // Process providers.
        environment.auth.providers.forEach(value => this.addProvider(value.name, value.type, value.params || {}));

        // Set token on init
        this.authTokenObservable.next(this.getToken());
    }

    /**
     * Get access token.
     */
    getToken(): string | null {
        return this.storage.getItem('auth.' + environment.auth.token_name);
    }

    /**
     * Remove token.
     */
    removeToken() {
        this.storage.removeItem('auth.' + environment.auth.token_name);
        this.setToken();
    }

    /**
     * Set token.
     */
    setToken(token: string | null = null) {
        this.storage.setItem('auth.' + environment.auth.token_name, token);
        this.authTokenObservable.next(token);
    }

    private validateResult(result): Promise<any> {
        if (result['code']) {
            this.removeToken();
            return Promise.reject(result);
        }

        this.setToken(result);
        return Promise.resolve(result);
    }

    async getUser(cache = true): Promise<Admin | null> {
        if (!this.getToken()) {
            return null;
        }

        return await this.connector.getUser(cache);
    }

    /**
     * Add new provider.
     *
     * @param name
     * @param type
     * @param params
     * @return {AuthService}
     */
    addProvider(name: string, type: string, params: AuthProviderParamsInterface): AuthService {
        switch (type) {
            case 'credential':
                this.providers.push(new AuthCredentialProvider(name, params, this.connector));
                break;
            default:
                throw Error('Not support provider type type.');
        }

        return this;
    }

    /**
     * Get auth provider list.
     */
    getProviders(): AuthProviderInterface[] {
        return this.providers;
    }

    /**
     * Get auth provider by name.
     */
    getProvider(name: string): AuthProviderInterface {
        return this.getProviders().find(provider => provider.getName() === name);
    }

    /**
     * Authenticate by provider name and params.
     */
    authenticate(name: string, params?): Promise<any> {
        const provider = this.getProvider(name);

        if (!provider) {
            return Promise.reject();
        }

        return provider
            .authenticate(params || {})
            .then(auth => {
                return this.getConnector().authenticate(provider.getName(), auth);
            })
            .then(result => {
                return this.validateResult(result);
            });
    }

    /**
     * Get auth connector
     */
    getConnector(): AuthConnectorInterface {
        return this.connector;
    }
}
