import { Component, forwardRef, Output, EventEmitter, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, UntypedFormControl } from '@angular/forms';
import { FileManager } from '@app/shared/service/file-manager';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FileUploadComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: FileUploadComponent,
            multi: true,
        },
    ],
})
export class FileUploadComponent implements ControlValueAccessor {
    file: File;
    fileName: string;
    fileUrl: string;
    fileExtension: string;
    uploading = false;

    @Input() path: string;
    @Input() required = true;

    imageExtensions = ['png', 'jpg', 'jpeg', 'gif', 'svg', 'bmp'];
    audioExtensions = ['mp3', 'ogg'];

    @Input()
    accepts: 'string';

    @Input()
    showDeleteButton = true;

    @Output() change = new EventEmitter<string>();

    _onChange = (_: any) => {};
    _onTouched = () => {};

    constructor(private fileManager: FileManager, private matSnackBar: MatSnackBar) {}

    writeValue(value: string) {
        if (!value) {
            this.fileUrl = null;
            return;
        }

        if (typeof value !== 'string') {
            return;
        }

        this.fileUrl = value;
        this.fileExtension = this.getExtension(value);
    }

    registerOnChange(fn) {
        this._onChange = fn;
    }

    registerOnTouched(fn) {
        this._onTouched = fn;
    }

    async onChange(event: Event) {
        const target = event.target as HTMLInputElement;
        const file = target.files[0];

        if (!file) {
            return;
        }

        this.uploading = true;

        this.fileManager.upload(file, this.path).subscribe(
            upload => {
                this.fileUrl = upload.url;
                this.fileExtension = this.getExtension(upload.url);

                this._onChange(this.fileUrl);
                this.change.emit(this.fileUrl);

                this.uploading = false;
            },
            err => {
                this.fileUrl = null;
                this.matSnackBar.open(err.message, 'Закрыть', { duration: 10000 });
                this.uploading = false;
            },
        );

        this.fileName = file.name;
        this.file = file;

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
            this.fileUrl = <string>reader.result;
        };

        target.value = null;
    }

    clearFile() {
        this.fileUrl = null;
        this._onChange(this.fileUrl);
        this.change.emit(this.fileUrl);
    }

    validate(control: UntypedFormControl) {
        const isNotValid = !this.fileUrl && this.required;
        return (
            isNotValid && {
                invalid: true,
            }
        );
    }

    getExtension(value: string): string {
        return value.split('.').pop();
    }
}
