import { Injectable } from '@angular/core';
import { Company } from '../models/company/company';
import { CompanyChallenge } from '../models/company/company-challenge';
import { CompanyChallengeGame } from '../models/company/company-challenge-game';
import { CompanyUser } from '../models/company/company-user';
import { CompanyUserStatistic } from '../models/company/company-user-statistic';
import { PaginationList } from '../models/pagination-list';
import { Rpc } from '../rpc';

@Injectable({
    providedIn: 'root',
})
export class CompanyInternalApiService {
    constructor(protected rpc: Rpc) {}

    getAll(): Promise<Company[]> {
        return this.rpc.call('internal.company.getAll');
    }

    getCompanyById(companyId: number): Promise<Company> {
        return this.rpc.call('internal.company.getById', companyId);
    }

    createCompany({
        name,
        slug,
        logo,
        logoHeader,
        description,
        priceSetId,
    }: {
        name: string | null;
        slug: string | null;
        logo: string | null;
        logoHeader: string | null;
        description: string | null;
        priceSetId: string | null;
    }): Promise<boolean> {
        return this.rpc.call('internal.company.create', { name, slug, logo, logoHeader, description, priceSetId });
    }

    updateCompany(
        companyId: number,
        name: string | null,
        slug: string | null,
        logo: string | null,
        logoHeader: string | null,
        description: string | null,
        priceSetId: string | null,
    ): Promise<boolean> {
        return this.rpc.call('internal.company.update', {
            companyId,
            name,
            slug,
            logo,
            logoHeader,
            description,
            priceSetId,
        });
    }

    findCompany(name: string | null, pageSize: number, pageIndex: number): Promise<PaginationList<Company>> {
        return this.rpc.call('internal.company.find', { name, pageSize, pageIndex });
    }

    getUsersInCompany(companyId: number): Promise<CompanyUser[]> {
        return this.rpc.call('internal.company.getUsers', companyId);
    }

    enableCompanyAdmin(userId: number, enable: boolean): Promise<boolean> {
        return this.rpc.call('internal.company.enableAdmin', { userId, enable });
    }

    createCompanyChallenge(
        companyId: number,
        name: string,
        startDate: string,
        finishDate: string,
        games: Omit<CompanyChallengeGame, 'id'>[],
    ): Promise<boolean> {
        return this.rpc.call('internal.company.createChallenge', { companyId, name, startDate, finishDate, games });
    }

    updateCompanyChallenge(
        challengeId: number,
        name: string,
        startDate: string,
        finishDate: string,
        games: Omit<CompanyChallengeGame, 'id'>[],
    ): Promise<boolean> {
        return this.rpc.call('internal.company.updateChallenge', { challengeId, name, startDate, finishDate, games });
    }

    getCompanyChallengeById(challengeId: number): Promise<CompanyChallenge> {
        return this.rpc.call('internal.company.getChallengeById', challengeId);
    }

    getCompanyChallenges(
        companyId: number,
        pageSize: number,
        pageIndex: number,
    ): Promise<PaginationList<CompanyChallenge>> {
        return this.rpc.call('internal.company.getChallenges', { companyId, pageSize, pageIndex });
    }

    sendParticipantsCertificate(challengeId: number): Promise<boolean> {
        return this.rpc.call('internal.company.sendParticipantsCertificate', challengeId);
    }

    sendStructureCertificate(challengeId: number): Promise<boolean> {
        return this.rpc.call('internal.company.sendStructureCertificate', challengeId);
    }

    getCompanyStatistics(
        companyId: number,
        from: string,
        to: string,
        challengeIds: string | null,
    ): Promise<CompanyUserStatistic[]> {
        return this.rpc.call('internal.company.statistics', { companyId, from, to, challengeIds });
    }
}
