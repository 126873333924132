<div class="portal-sidenav-theme" fxLayout="column" fxFlexFill>
    <mat-toolbar>
        <span i18n>Apps</span>
        <span fxFlex></span>
        <button mat-icon-button (click)="menuDrawer.close()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-toolbar>
    <div class="portal-funky-drawer-menu" fxFlex="nogrow noshrink">
        <mat-nav-list>
            <a
                mat-list-item
                [routerLink]="['/apps/email', { outlets: { list: 'inbox', messages: 'none' } }]"
                routerLinkActive="portal-menu--active"
            >
                <mat-icon matListIcon>email</mat-icon>
                <h3 matLine>Email</h3>
            </a>
            <a mat-list-item routerLink="/apps/todo" routerLinkActive="portal-menu--active">
                <mat-icon matListIcon>check_circle</mat-icon>
                <h3 matLine>Todo</h3>
            </a>
            <a mat-list-item routerLink="/apps/maps" routerLinkActive="portal-menu--active">
                <mat-icon matListIcon>pin_drop</mat-icon>
                <h3 matLine>Maps</h3>
            </a>
            <a mat-list-item routerLink="/apps/chat" routerLinkActive="portal-menu--active">
                <mat-icon matListIcon>chat</mat-icon>
                <h3 matLine>Chat</h3>
            </a>
            <a mat-list-item routerLink="/apps/calendar" routerLinkActive="portal-menu--active">
                <mat-icon matListIcon>date_range</mat-icon>
                <h3 matLine>Calendar</h3>
            </a>
            <a mat-list-item routerLink="/apps/contacts" routerLinkActive="portal-menu--active">
                <mat-icon matListIcon>face</mat-icon>
                <h3 matLine>Contacts</h3>
            </a>
            <a mat-list-item routerLink="/apps/notes" routerLinkActive="portal-menu--active">
                <mat-icon matListIcon>event_note</mat-icon>
                <h3 matLine>Notes</h3>
            </a>
        </mat-nav-list>
    </div>
    <mat-toolbar>
        <mat-toolbar-row>
            <span fxFlex></span>
            <button mat-icon-button>
                <mat-icon>info</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>
</div>
