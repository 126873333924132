import { CacheOptions } from './cache.options';
import { CacheInterface } from './cache.interface';

export abstract class CacheAbstract implements CacheInterface {
    /**
     * Options.
     */
    protected options: CacheOptions;

    /**
     * @param options
     */
    protected constructor(options: CacheOptions) {
        this.options = options;
    }

    /**
     * Get storage options.
     */
    getOptions() {
        return this.options;
    }

    /**
     * Is key exist.
     */
    abstract has(key: string): boolean;

    /**
     * Set or update cache key.
     */
    abstract set(key: string, value: any): void;

    /**
     * Get from cache.
     */
    abstract get(key: string, def?: any): any | null;

    /**
     * Remove from cache.
     */
    abstract remove(key: string): void;

    /**
     * Clear all in cache.
     */
    abstract clear(): void;
}
