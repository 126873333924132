import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { cloneDeep, isEqual } from 'lodash';
import { Moment } from 'moment';
import { debounceTime } from 'rxjs/operators';

export const MY_FORMATS = {
    parse: {
        dateInput: 'MM/YYYY',
    },
    display: {
        dateInput: 'MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'app-month-and-year-selector',
    templateUrl: './month-and-year-selector.component.html',
    styleUrls: ['./month-and-year-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: MonthAndYearSelector,
            multi: true,
        },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
})
export class MonthAndYearSelector implements OnInit, ControlValueAccessor {
    @Input() label: string = 'Месяц/год';
    @Input() _date: Moment = null;
    @Output()
    selected: EventEmitter<{ from: string; to: string }> = new EventEmitter();

    previousSelected = { to: null, from: null };

    onChange;
    date = new UntypedFormControl();

    constructor() {}

    ngOnInit(): void {
        if (this._date) {
            this.date.setValue(this._date);
        }

        this.date.valueChanges.pipe(debounceTime(400)).subscribe(x => {
            if (!this.date.errors) {
                this.emit();
            }
        });
    }

    chosenYearHandler(selectedYear: Moment) {
        const value = this.date.value;
        if (value) {
            value.year(selectedYear.year());
            this.date.setValue(value);
        } else {
            this.date.setValue(selectedYear);
        }
    }

    chosenMonthHandler(selectedMonth: Moment, datepicker: MatDatepicker<Moment>) {
        const value = this.date.value;
        if (value) {
            value.month(selectedMonth.month());
            this.date.setValue(value);
        } else {
            this.date.setValue(selectedMonth);
        }

        datepicker.close();
    }

    emit() {
        const emit = this.getFormattedValue();
        if (!isEqual(emit, this.previousSelected)) {
            this.selected.emit(emit);

            this.previousSelected = cloneDeep(emit);
        }
    }

    getFormattedValue() {
        return {
            from: this.date.value.startOf('month').format('YYYY-MM-DD'),
            to: this.date.value.endOf('month').format('YYYY-MM-DD'),
        };
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        // not use
    }

    setDisabledState(isDisabled: boolean): void {
        // not use
    }

    writeValue(date: Moment): void {
        if (date) {
            this.date.setValue(date);
        }
    }
}
