import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { StocksApiService } from '@app/shared/api/stocks-api.service';
import { PriceClassList } from '@models/stock/price-class-list';

// @ts-ignore
@Component({
    selector: 'app-stock-price-class-select',
    templateUrl: './stock-price-class-select.component.html',
    styleUrls: ['./stock-price-class-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: StockPriceClassSelectComponent,
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockPriceClassSelectComponent implements ControlValueAccessor {
    onChange: (value: string | null) => void;
    value: string | null = null;
    list = PriceClassList;

    constructor(private stocksApiService: StocksApiService, private changeDetectorRef: ChangeDetectorRef) {}

    selectValue(value: string | null) {
        if (this.onChange) {
            this.onChange(value);
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {}

    setDisabledState(isDisabled: boolean): void {}

    writeValue(value: any): void {
        this.value = value;
        this.changeDetectorRef.markForCheck();
    }
}
