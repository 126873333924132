import { ConnectorInterface } from './connector.interface';
import { RpcRequest } from '../request';
import { RpcResponse } from '../response';
import { fromFetch } from 'rxjs/fetch';
import { of, throwError } from 'rxjs';
import { mergeMap, retry, switchMap, catchError } from 'rxjs/operators';

/**
 * Rpc local connector options.
 */
export interface RpcXhrConnectorOptions {
    address: string | string[];
    maxAttemptCount: number;
}

/**
 * Rpc xhr connector.
 */
export class RpcXhrConnector implements ConnectorInterface {
    /**
     * Token.
     */
    protected token: string | null = null;

    /**
     * @param options
     */
    constructor(private options: RpcXhrConnectorOptions) {}

    /**
     * Get address rpc server.
     *
     * @return string
     */
    public getAddress(): string {
        const address = this.options.address;

        if (!address) {
            throw Error('No RPC address');
        }

        if (typeof address === 'string') {
            return address;
        }

        return address[Math.floor(Math.random() * address.length)];
    }

    /**
     * Exec request.
     */
    public exec(request: RpcRequest): Promise<RpcResponse | null> {
        const body = {
            jsonrpc: '2.0',
            method: request.method,
        };

        if (request['id']) {
            body['id'] = request['id'];
        }

        if (request['params']) {
            body['params'] = request['params'];
        }

        const headers = {
            'Content-Type': 'application/json-rpc',
        };

        if (this.token && this.token !== 'null') {
            headers['Authorization'] = 'Bearer ' + this.token;
        }

        return fromFetch(this.getAddress(), {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
            credentials: this.token && this.token !== 'null' ? 'omit' : 'include',
        })
            .pipe(
                switchMap((response: Response) => {
                    return response.json();
                }),
                mergeMap((json: RpcResponse) => {
                    if (!json) {
                        return throwError(`Oops! no results from server`);
                    }

                    if (!json.hasOwnProperty('error') && !json.hasOwnProperty('result')) {
                        return throwError(`Oops! empty result from server`);
                    }

                    return of(<RpcResponse>json);
                }),
                retry(this.options.maxAttemptCount || 2),
            )
            .toPromise();
    }

    /**
     * Delete token
     */
    deleteToken(): void {
        this.token = null;
    }

    /**
     * Set token
     */
    setToken(token: string): void {
        this.token = token;
    }

    /**
     * Get connector options
     */
    getOptions() {
        return this.options;
    }
}
