import {
    Filter,
    MoreOrLess,
    YesOrNo,
    Select,
    TagsSelect,
    Radio,
    YesOrNoWithSingleOption,
    MoreOrLessWithOption,
    YesOrNoWithList,
    Yes,
    DateRange,
    YesOrNoWithSingleOptionAndValue,
    Number,
    DateRangeWithOption,
} from '@app/shared/models/cohort/filter';
import { cloneDeep } from 'lodash';
import { InternalApiService } from '@app/shared/api/internal-api.service';
import { forkJoin } from 'rxjs';
import { CohortApiService } from '@app/shared/api/cohort-api.service';

export class CohortFilterProviderService {
    filters: Filter<any>[] = [];

    constructor(private internalApi: InternalApiService, private cohortApi: CohortApiService) {}

    async init() {
        if (this.filters.length) {
            return;
        }

        this.filters.push(new MoreOrLess('CompletedTrainsNum'));
        this.filters.push(new YesOrNo('ConfirmedEmail'));
        this.filters.push(new MoreOrLess('DaysFromLastVisit'));
        this.filters.push(new MoreOrLess('DaysFromRegistration'));
        this.filters.push(
            new Radio('EvenOdd', {
                options: [
                    { key: 'Четный', value: 'even' },
                    { key: 'Нечетный', value: 'odd' },
                ],
            }),
        );
        this.filters.push(new YesOrNo('HasCompany'));
        this.filters.push(new YesOrNo('HasCompletedOrders'));
        this.filters.push(new YesOrNo('HasPhone'));
        this.filters.push(new YesOrNo('HasPlatinum'));
        this.filters.push(new Yes('HasPromoSubscription'));

        this.filters.push(new YesOrNoWithList('InEmailsList'));
        this.filters.push(new YesOrNoWithList('EmailDomain'));

        this.filters.push(
            new Radio('Locale', {
                options: [
                    { key: 'ru', value: 'ru' },
                    { key: 'en', value: 'en' },
                    { key: 'es', value: 'es' },
                ],
            }),
        );
        this.filters.push(
            new Radio('Mobile', {
                options: [
                    { key: 'ios', value: 'ios' },
                    { key: 'android', value: 'android' },
                ],
            }),
        );
        this.filters.push(new MoreOrLess('OrdersNum'));
        this.filters.push(new MoreOrLess('OrdersSum'));
        this.filters.push(new MoreOrLess('PremiumDaysLeft'));
        this.filters.push(new MoreOrLess('UserId'));
        this.filters.push(new MoreOrLess('VisitsNum'));

        const data = await forkJoin({
            countries: this.internalApi.getCountries(),
            address: this.internalApi.getGeocoderAddress(),
            cohorts: this.cohortApi.getAll().then(cohorts => {
                const options = [];
                for (let [slug, name] of Object.entries(cohorts)) {
                    options.push({ value: slug, key: name });
                }

                return options;
            }),
            marks: this.internalApi.getUserMarks(),
            products: this.internalApi.getProducts(),
            userStats: this.internalApi.getUserStats(),
        }).toPromise();

        this.filters.push(
            new TagsSelect('Country', {
                options: data.countries.map(c => {
                    return { value: c.code, key: c.name };
                }),
            }),
        );
        this.filters.push(
            new TagsSelect('Geo', {
                options: data.address
                    .filter(a => a.localityName && a.isActive)
                    .map(a_1 => {
                        return { value: a_1.id, key: a_1.localityNameRu || a_1.localityName };
                    }),
            }),
        );
        this.filters.push(new Select('InCohort', { options: data.cohorts }));
        this.filters.push(new Select('NotInCohort', { options: data.cohorts }));
        this.filters.push(
            new YesOrNoWithSingleOption('HasMark', {
                options: data.marks.map(m => {
                    return { key: m, value: m };
                }),
            }),
        );
        this.filters.push(
            new YesOrNoWithSingleOption('HasOrderWithProduct', {
                options: data.products.map(p => {
                    return { key: p.name, value: p.product };
                }),
            }),
        );
        this.filters.push(
            new YesOrNoWithSingleOption('HasStat', {
                options: data.userStats.map(us => {
                    return { key: us.name, value: us.name };
                }),
            }),
        );
        this.filters.push(
            new YesOrNoWithSingleOptionAndValue('HasStatWithValue', {
                options: data.userStats.map(us => {
                    return { key: us.name, value: us.name };
                }),
            }),
        );
        this.filters.push(new DateRange('RegistrationRange'));
        // TODO
        //   this.filters.push(
        //       new YesOrNoWithSingleOption('InSnapshot', {
        //           options: data.snapshots.map(us => {
        //               return { key: s.name, value: s.id };
        //           }),
        //       }),
        //   );
        this.filters.push(
            new MoreOrLessWithOption('DaysFromPurchase', {
                options: data.products.map(p_1 => {
                    return { key: p_1.name, value: p_1.ns + '.' + p_1.product };
                }),
            }),
        );

        this.filters.push(new YesOrNo('HasBirthday'));
        this.filters.push(new Number('YoungerThan'));
        this.filters.push(new Number('OlderThan'));
        this.filters.push(new YesOrNo('IsPartner'));

        this.filters.push(
            new DateRangeWithOption('PurchaseRange', {
                options: data.products.map(p_1 => {
                    return { key: p_1.name, value: p_1.ns + '.' + p_1.product };
                }),
            }),
        );
        // https://jira.wikium.io/browse/WKM-11231
        this.filters.push(new YesOrNo('HasActivatedGift'));
    }

    getNames(): string[] {
        return this.filters.map(f => f.name);
    }

    get(type: string): Filter<any> {
        const filter = cloneDeep(this.filters.find(f => f.name == type));

        filter.key = filter.name + Math.random() * 99999999 + Date.now() + '';

        return filter;
    }
}

const filterProviderFactory = (internalApi: InternalApiService, cohortApi: CohortApiService) => {
    return new CohortFilterProviderService(internalApi, cohortApi);
};

export let cohortFilterProviderProvider = {
    provide: CohortFilterProviderService,
    useFactory: filterProviderFactory,
    deps: [InternalApiService, CohortApiService],
};
