import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BannerApiService } from '@app/shared/api/banner/banner-api.service';
import { CollectionType } from '@models/banner/collection-type';

@Component({
    selector: 'app-banner-collection-type-select',
    templateUrl: './banner-collection-type-select.component.html',
    styleUrls: ['./banner-collection-type-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: BannerCollectionTypeSelectComponent,
            multi: true,
        },
    ],
})
export class BannerCollectionTypeSelectComponent implements OnInit, ControlValueAccessor {
    onChange: Function;
    types: CollectionType[] = [];
    value: CollectionType | null = null;

    @Input()
    disabled = false;

    constructor(private bannerApiService: BannerApiService) {}

    async ngOnInit() {
        this.types = await this.bannerApiService.getAllCollectionType();
    }

    selectValue(value: number | null) {
        if (this.onChange) {
            this.onChange(value);
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {}

    setDisabledState(isDisabled: boolean): void {}

    writeValue(value: any): void {
        this.value = value;
    }

    compare(c1, c2): boolean {
        return c2 && c1.id == c2.id;
    }
}
