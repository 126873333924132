import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CohortApiService } from '@app/shared/api/cohort-api.service';
import { Cohort } from '@models/cohort';
import { CohortFilterProviderService } from '@app/shared/service/cohort-filter-provider.service';
import { Node } from '@app/shared/models/cohort/node';

@Component({
    selector: 'app-cohort-info-dialog',
    templateUrl: './cohort-info-dialog.component.html',
    styleUrls: ['./cohort-info-dialog.component.scss'],
})
export class CohortInfoDialogComponent implements OnInit {
    isWaiting = true;
    count: number;
    cohort: Cohort;

    filters: any;

    constructor(@Inject(MAT_DIALOG_DATA) public slug: string | null, private cohortApiService: CohortApiService,  private provider: CohortFilterProviderService) {}

    async ngOnInit() {
        this.cohort = await this.cohortApiService.getBySlug(this.slug),
        await this.provider.init();
        this.filters = this.buildNode(this.cohort['filterV2'])
        this.isWaiting = false;
    }

    private buildNode(rawNode: any): Node {
        if (!rawNode) {
            return null;
        }
        const node = new Node(rawNode.type);

        ((rawNode.filters as any[]) || []).forEach(rawFilter => {
            const filter = this.provider.get(rawFilter.name);
            filter.value = rawFilter.value !== undefined ? rawFilter.value : null;
            node.filters.push(filter);
        });

        ((rawNode.children as any[]) || []).forEach(rawChild => {
            node.children.push(this.buildNode(rawChild));
        });

        return node;
    }

    async getCount() {
        this.isWaiting = true;
        this.count = await this.cohortApiService.getCount(this.slug);
        this.isWaiting = false;
    }
}
