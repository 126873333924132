<mat-form-field class="product-select" appearance="outline">
    <mat-label>Укажите продукт</mat-label>

    <input type="text" matInput [matAutocomplete]="auto" [formControl]="inputControl" [required]="required" />

    <mat-autocomplete (optionSelected)="onOptionSelect($event)" #auto="matAutocomplete">
        <mat-option *ngFor="let product of filteredOptions | async" [value]="product.product">
            {{ product.name }}
        </mat-option>
    </mat-autocomplete>

    <mat-error *ngIf="inputControl.errors?.required">
        Это поле обязательно для заполнения
    </mat-error>

    <mat-error *ngIf="inputControl.errors?.notFound">
        Продукт не найден
    </mat-error>
</mat-form-field>
