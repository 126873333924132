import { Directive, Input, OnChanges, OnInit, SimpleChange, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthSecurity } from '@app/shared/auth/security/security.service';

@Directive({
    selector: '[appIfRole]',
})
export class IfRoleDirective implements OnInit, OnChanges {
    @Input('appIfRole')
    role: string | string[];

    constructor(
        private container: ViewContainerRef,
        private template: TemplateRef<object>,
        private security: AuthSecurity,
    ) {}

    async ngOnChanges(changes: { [property: string]: SimpleChange }) {
        let roles = [];

        if (typeof changes.role.currentValue === 'string') {
            roles.push(changes.role.currentValue);
        } else {
            roles = changes.role.currentValue;
        }

        const isGrant = await this.security.isGrant(roles);

        if (isGrant) {
            this.container.createEmbeddedView(this.template).detectChanges();
        } else {
            this.container.clear();
        }
    }

    ngOnInit(): void {
        this.container.clear();
    }
}
