import { Injectable } from '@angular/core';
import { Rpc } from '@app/shared/rpc';
import { BannerSpot } from '@models/banner/banner-spot';
import { Banner } from '@app/shared/models/banner/banner';
import { Variable } from '@app/shared/models/banner/variable';
import { PaginationList } from '@models/pagination-list';

@Injectable({
    providedIn: 'root',
})
export class SpotApiService {
    constructor(protected rpc: Rpc) {}

    list(): Promise<BannerSpot[]> {
        return this.rpc.call('banner.banner_spot.list');
    }

    create(spot: BannerSpot): Promise<BannerSpot> {
        return this.rpc.call('banner.banner_spot.create', {
            name: spot.name,
            slug: spot.slug,
        });
    }

    update(spot: BannerSpot): Promise<BannerSpot> {
        return this.rpc.call('banner.banner_spot.update', {
            id: spot.id,
            name: spot.name,
            slug: spot.slug,
        });
    }
}
