<mat-form-field appearance="outline" class="game-select">
    <mat-label>
        Тренажер
    </mat-label>
    <mat-select [(ngModel)]="value" (selectionChange)="selectValue($event.value)" [required]="required">
        <mat-option [value]="null">Не выбрано</mat-option>
        <mat-option *ngFor="let game of games" [value]="game[ngModelField]">
            {{ game.name }}
        </mat-option>
    </mat-select>
</mat-form-field>
