import { Component, forwardRef, Input, ViewEncapsulation } from '@angular/core';
import { Options } from 'ngx-quill-upload';
import { FileManager } from '@app/shared/service/file-manager';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-editor',
    templateUrl: './editor.component.html',
    styleUrls: [
        './editor.component.scss',
        '../../../../../node_modules/quill/dist/quill.core.css',
        '../../../../../node_modules/quill/dist/quill.bubble.css',
        '../../../../../node_modules/quill/dist/quill.snow.css',
    ],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EditorComponent),
            multi: true,
        },
    ],
})
export class EditorComponent implements ControlValueAccessor {
    modules = {
        imageHandler: {
            upload: file => {
                return new Promise((resolve, reject) => {
                    this.fileManager.upload(file).subscribe(upload => {
                        resolve(upload.url);
                    });
                });
            },
            accepts: ['png', 'jpg', 'jpeg', 'jfif', 'gif'], // Extensions to allow for images (Optional) | Default - ['jpg', 'jpeg', 'png']
        } as Options,
    };

    constructor(private fileManager: FileManager) {}

    @Input()
    content: string;

    @Input()
    styles = { 'max-height': '250px', overflow: 'auto' };

    @Input() required = false;

    @Input() editorOptions = null;

    onChange: Function;

    selectValue(value: any) {
        console.dir(value);
        if (this.onChange && value.html !== undefined) {
            this.onChange(value.html);
        }
    }

    registerOnChange(fn): void {
        this.onChange = fn;
    }

    registerOnTouched(fn): void {}

    setDisabledState(isDisabled: boolean): void {}

    writeValue(v: any): void {
        this.content = v;
    }

    test(c) {
        console.dir(c);
    }
}
