import { Component, Input, OnInit } from '@angular/core';
import { CohortApiService } from '@app/shared/api/cohort-api.service';
import { keys } from 'lodash';
import { Observable } from 'rxjs';
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validators,
} from '@angular/forms';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'app-cohort-select',
    templateUrl: './cohort-select.component.html',
    styleUrls: ['./cohort-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: CohortSelectComponent,
            multi: true,
        },
    ],
})
export class CohortSelectComponent implements OnInit, ControlValueAccessor {
    @Input() required = false;

    inputControl: FormControl<string | null> = new FormControl();
    onChange: Function;
    filteredOptions: Observable<string[]>;
    options: string[];

    constructor(private cohortApiService: CohortApiService) {}

    async ngOnInit() {
        this.options = keys(await this.cohortApiService.getAll());
        const validators = [];

        validators.push((control: AbstractControl): ValidationErrors | null => {
            if (!this.options || !control.value) {
                return null;
            }

            const index = this.options.map(option => option.toLowerCase()).indexOf(control.value.toLowerCase());

            return index > -1 ? null : { notFound: { value: control.value } };
        });

        this.inputControl.setValidators(validators);

        this.filteredOptions = this.inputControl.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value)),
        );
    }

    private _filter(value: string): string[] {
        const filterValue = (value || '').toLowerCase();
        return (this.options || []).filter(option => option.toLowerCase().includes(filterValue));
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {}

    setDisabledState(isDisabled: boolean): void {}

    writeValue(value: string): void {
        this.inputControl.setValue(value);
    }

    onOptionSelect($event) {
        this.writeValue($event.option.value);

        if (this.onChange) {
            this.onChange($event.option.value);
        }
    }

    clear() {
        this.writeValue(null);

        if (this.onChange) {
            this.onChange(null);
        }
    }
}
