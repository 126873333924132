import { Component, forwardRef, OnInit } from '@angular/core';
import { TildaPage } from '@models/course/tilda-page';
import { TildaApiService } from '@app/shared/api/tilda-api.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-tilda-page-select',
    templateUrl: './tilda-page-select.component.html',
    styleUrls: ['./tilda-page-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => TildaPageSelectComponent),
        },
    ],
})
export class TildaPageSelectComponent implements OnInit, ControlValueAccessor {
    tildaPages: TildaPage[] = [];
    value: TildaPage | null = null;

    constructor(private tildaApiService: TildaApiService) {}

    async ngOnInit() {
        this.tildaPages = await this.tildaApiService.getAllPage();
    }

    onChange: Function;

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {}

    writeValue(val: any): void {
        this.value = val;
    }

    changeVal(event) {
        if (this.onChange) {
            this.onChange(event.value);
        }
    }
}
