<div fxLayout="column" fxLayoutAlign="center center" [appWaitAndBlock]="isWaiting">
    <h2 mat-dialog-title>Информация о когорте {{ slug }}</h2>

    <mat-dialog-content class="cohort-info-dialog__content">
        <p>
            <b>ID:</b>
            {{ cohort?.id }}
        </p>
        <p>
            <b>Название:</b>
            {{ cohort?.name }}
        </p>
        <p>
            <b>Slug:</b>
            {{ cohort?.slug }}
        </p>
        <p>
            <b>Публичная:</b>
            {{ cohort?.isPublic ? 'Да' : 'Нет' }}
        </p>
        <p *appIfRole="['ROLE_ADMIN']">
            <b>Кол-во пользователей:</b>
            <ng-container *ngIf="count || count === 0; else btn">{{ count > 0 ? count : 'Нет данных' }}</ng-container>
            <ng-template #btn>
                <button mat-button color="primary" (click)="getCount()">Узнать количество</button>
            </ng-template>
        </p>
        <p *ngIf="filters">
            <b>Условия:</b>
            <app-cohort-filters [node]="filters"></app-cohort-filters>
        </p>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-flat-button [mat-dialog-close]="true">Закрыть</button>
    </mat-dialog-actions>
</div>
