import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BannerSpot } from '@models/banner/banner-spot';
import { SpotApiService } from '@app/shared/api/banner/spot-api.service';

@Component({
    selector: 'app-banner-spot-select',
    templateUrl: './banner-spot-select.component.html',
    styleUrls: ['./banner-spot-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: BannerSpotSelectComponent,
            multi: true,
        },
    ],
})
export class BannerSpotSelectComponent implements OnInit, ControlValueAccessor {
    @Input() typeModel = false;
    @Input() disabled = false;

    onChange: Function;
    types: BannerSpot[] = [];
    value = null;

    constructor(private spotApiService: SpotApiService) {}

    async ngOnInit() {
        this.types = await this.spotApiService.list();
    }

    selectValue(value: string | null | BannerSpot) {
        if (this.onChange) {
            this.onChange(value);
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {}

    setDisabledState(isDisabled: boolean): void {}

    writeValue(value: any): void {
        this.value = value;
    }

    compare(c1, c2): boolean {
        if (typeof c1 === 'string') {
            return c1 == c2;
        }

        return c2 && c1.id == c2.id;
    }
}
