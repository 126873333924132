import { Filter } from './filter';

export class MoreOrLessWithOption extends Filter<{ gte: number; lte: number; option: string | number }> {
    controlType = 'more_or_less_with_option';
    value = { gte: null, lte: null, option: null };

    isEmpty(): boolean {
        return (
            this.value === null || (this.value.gte === null && this.value.lte === null && this.value.option === null)
        );
    }
}
