<div class="node">
    <h4>
        <b>{{ 'cohort.glue.' + node?.type | translate }}</b>
    </h4>

    <div *ngIf="node?.children?.length == 0" class="node__list">
        <div class="node__item" *ngFor="let filter of node.filters; let last = last">
            <div class="node__item-content">
                {{ 'cohort.filter.' + filter.name | translate }}
                <ng-container [ngSwitch]="filter.controlType">
                    <ng-container *ngSwitchCase="'number'">
                        <div>
                            <b>{{ filter.value }}</b>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="'text'">
                        <div>
                            <b>{{ filter.value }}</b>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="'more_or_less'">
                        <ng-container *ngIf="filter.value.gte">
                            <div>
                                <b>{{ 'больше ' + filter.value.gte }}</b>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="filter.value.lte">
                            <div>
                                <b>{{ 'меньше ' + filter.value.lte }}</b>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'more_or_less_with_option'">
                        <div>
                            <b>{{ getName(filter.options, filter.value.option) }}</b>
                        </div>
                        <ng-container *ngIf="filter.value.gte">
                            <div>
                                <b>{{ 'больше ' + filter.value.gte }}</b>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="filter.value.lte">
                            <div>
                                <b>{{ 'меньше ' + filter.value.lte }}</b>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'yes_or_no'">
                        <div>
                            <b>{{ filter.value ? 'Да' : 'Нет' }}</b>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="'select'">
                        <div>
                            <b>{{ getName(filter.options, filter.value) }}</b>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="'tags_select'">
                        <div *ngFor="let item of filter.value">
                            <b>{{ getName(filter.options, item) }}</b>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="'yes_or_no_with_tags'">
                        <div>
                            <b>{{ filter.value.has ? 'Да' : 'Нет' }}</b>
                        </div>
                        <div *ngFor="let item of filter.value.options">
                            <b>{{ getName(filter.options, item) }}</b>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="'yes_or_no_with_list'">
                        <div>
                            <b>{{ filter.value ? 'Да' : 'Нет' }}</b>
                        </div>
                        <div *ngFor="let item of filter.value.options">{{ item }}</div>
                    </ng-container>

                    <ng-container *ngSwitchCase="'radio'">
                        <div>
                            <b>{{ getName(filter.options, filter.value) }}</b>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="'yes_or_no_with_single_option'">
                        <div>
                            <b>{{ filter.value.has ? 'Да' : 'Нет' }}</b>
                        </div>
                        <div>
                            <b>{{ getName(filter.options, filter.value.option) }}</b>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="'yes_or_no_with_single_option_and_value'">
                        <div>
                            <b>{{ filter.value.has ? 'Да' : 'Нет' }}</b>
                        </div>
                        <div>
                            <b>{{ getName(filter.options, filter.value.option) }}</b>
                        </div>
                        <div>
                            Значение:
                            <b>{{filter.value.value }}</b>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="'date_range'">
                        <div *ngIf="filter.value.from">
                            <b>{{ 'От: ' + filter.value.from }}</b>
                        </div>
                        <div *ngIf="filter.value.to">
                            <b>{{ 'До: ' + filter.value.to }}</b>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="'yes'"></ng-container>

                    <ng-container *ngSwitchCase="'date_range_with_option'">
                        <div>
                            <b>{{ getName(filter.options, filter.value.option) }}</b>
                        </div>
                        <div *ngIf="filter.value.from">
                            <b>{{ 'От: ' + filter.value.from }}</b>
                        </div>
                        <div *ngIf="filter.value.to">
                            <b>{{ 'До: ' + filter.value.to }}</b>
                        </div>
                    </ng-container>

                    <p *ngSwitchDefault>
                        no controll for
                        <strong>{{ filter.controlType }}</strong>
                    </p>
                </ng-container>
            </div>

            <div *ngIf="!last" class="node__item-glue">
                {{ 'cohort.glue.' + node.type | translate }}
            </div>
        </div>
    </div>
    <div *ngIf="node?.filters?.length == 0">
        <ng-container *ngFor="let child of node.children; let last = last">
            <div class="node__child">
                <app-cohort-filters [node]="child"></app-cohort-filters>
            </div>

            <div *ngIf="!last">
                {{ 'cohort.glue.' + node.type | translate }}
            </div>
        </ng-container>
    </div>
</div>
