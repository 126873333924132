import { ConnectorInterface } from './connector/connector.interface';
import { RpcRequest } from './request';
import { RpcResponse } from './response';

/**
 * Rpc client
 */
export class RpcClient {
    /**
     * Request id.
     */
    private ids = 0;

    /**
     * @param {ConnectorInterface} connector
     * @param name
     */
    constructor(private connector: ConnectorInterface, private name) {}

    /**
     * Set access token.
     */
    setToken(token: string | null) {
        this.connector.setToken(token);
    }

    /**
     * Delete access token.
     */
    deleteToken() {
        this.connector.deleteToken();
    }

    /**
     * Get connector.
     */
    getConnector(): ConnectorInterface {
        return this.connector;
    }

    /**
     * Call method.
     */
    call(method: string, params?: any): Promise<any> {
        const request = {
            method: method,
        };

        if (params) {
            if (typeof params === 'string' || typeof params === 'number') {
                request['params'] = [params];
            } else {
                request['params'] = params;
            }
        }

        request['id'] = this.getIdForRequest();

        return this.exec(<RpcRequest>request);
    }

    /**
     * Send request.
     */
    private exec(request: RpcRequest): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getConnector()
                .exec(request)
                .then(response => {
                    if (response && response.hasOwnProperty('result')) {
                        resolve(response['result']);
                    } else if (response && response.hasOwnProperty('error')) {
                        reject(response['error']);
                    } else {
                        reject();
                    }
                })
                .catch(reject);
        });
    }

    /**
     * Get id for request.
     *
     * @return {number}
     */
    getIdForRequest(): number {
        return ++this.ids;
    }

    /**
     * Get client name
     */
    getName() {
        return this.name;
    }

    /**
     * Get options
     */
    getOptions() {
        return this.connector.getOptions();
    }
}
