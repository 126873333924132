<div class="file-upload" [appWaitAndBlock]="uploading">
    <ng-container [ngTemplateOutlet]="default"></ng-container>

    <a
        *ngIf="fileUrl && imageExtensions.indexOf(fileExtension) !== -1; else audioCheck"
        [href]="fileUrl"
        class="file-upload__container"
        target="_blank"
    >
        <img src="{{ fileUrl }}" alt="fileName" class="file-upload__wrap" />
    </a>

    <ng-template #audioCheck>
        <a
            *ngIf="fileUrl && audioExtensions.indexOf(fileExtension) !== -1"
            [href]="fileUrl"
            class="file-upload__wrap link"
            target="_blank"
        >
            <mat-icon>volume_up</mat-icon>
            <span class="file-upload__url">{{ fileUrl }}</span>
        </a>
    </ng-template>

    <ng-template #default>
        <div class="file-upload__controls">
            <label class="file-upload__wrap">
                <mat-icon color="primary">cloud_upload</mat-icon>
                <span class="file-upload__name">Загрузите файл</span>
                <input
                    class="file-upload__input"
                    type="file"
                    name="file"
                    (change)="onChange($event)"
                    [attr.accept]="accepts"
                />
            </label>

            <button
                *ngIf="fileUrl && showDeleteButton"
                type="button"
                mat-icon-button
                (click)="clearFile()"
                matTooltip="Очистить файл"
            >
                <mat-icon color="primary">delete_forever</mat-icon>
            </button>
        </div>
    </ng-template>
</div>
