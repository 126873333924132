import 'moment/locale/ru';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MomentModule } from 'ngx-moment';
import { SharedMaterialModule } from '../shared-material.module';
import { MatCardModule } from '@angular/material/card';
import { IfRoleDirective } from '@app/shared/directives/if-role.directive';
import { WaitAndBlockDirective } from '@app/shared/directives/wait-and-block.directive';
import { CohortSelectComponent } from './components/cohort-select/cohort-select.component';
import { EmailTemplateSelectComponent } from '@app/shared/components/email-template-select/email-template-select.component';
import { StockMediaComponent } from './components/stock-media/stock-media.component';
import { SafePipe } from './pipe/safe.pipe';
import { BannerSpotSelectComponent } from '@app/shared/components/banner-spot-select/banner-spot-select.component';
import { BannerSelectComponent } from '@app/shared/components/banner-select/banner-select.component';
import { PriceSetSelectComponent } from './components/price-set-select/price-set-select.component';
import { FileUploadComponent } from '@app/shared/components/file-upload/file-upload.component';
import { ConfirmDialogComponent } from '@app/shared/dialogs/confirm-dialog/confirm-dialog.component';
import { StockSelectComponent } from '@app/shared/components/stock-select/stock-select.component';
import { HtmlPreviewComponent } from '@app/shared/components/html-preview/html-preview.component';
import { TimerSelectComponent } from '@app/shared/components/timer-select/timer-select.component';
import { TextListInputComponent } from './components/text-list-input/text-list-input.component';
import { TranslateModule } from '@ngx-translate/core';
import { CohortInfoDialogComponent } from '@app/shared/dialogs/cohort-info-dialog/cohort-info-dialog.component';
import { BannerCollectionTypeSelectComponent } from '@app/shared/components/banner-collection-type-select/banner-collection-type-select.component';
import { TagsSelectComponent } from './components/tags-select/tags-select.component';
import { AutoSelectComponent } from './components/auto-select/auto-select.component';
import { TextTagsSelectComponent } from './components/text-tags-select/text-tags-select.component';
import { DateTimeComponent } from './components/date-time/date-time.component';
import { StockPriceClassSelectComponent } from '@app/shared/components/stock-price-class-select/stock-price-class-select.component';
import { AngularResizeEventModule } from 'angular-resize-event';
import { TildaPageSelectComponent } from './components/tilda-page-select/tilda-page-select.component';
import { EditorComponent } from './components/editor/editor.component';
import { QuillModule } from 'ngx-quill';
import { ProductSelectComponent } from './components/product-select/product-select.component';
import { CourseAccessSelectComponent } from '@app/shared/components/course-access-select/course-access-select.component';
import { DatePeriodSelectorComponent } from './components/date-period-selector/date-period-selector.component';
import { ProductsSelectFromListComponent } from './components/products-select-from-list/products-select-from-list.component';
import { MonthAndYearSelector } from './components/month-and-year-selector/month-and-year-selector.component';
import { cohortFilterProviderProvider } from '@app/shared/service/cohort-filter-provider.service';
import { CohortFiltersComponent } from './components/cohort-filters/cohort-filters.component';
import { CohortsSelectFromListComponent } from './components/cohorts-select-from-list/cohorts-select-from-list.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { OrdersTableComponent } from './components/orders-table/orders-table.component';
import { RouterModule } from '@angular/router';
import { GetDataBySplittingRequestDialogComponent } from './dialogs/get-data-by-splitting-request-dialog/get-data-by-splitting-request-dialog.component';
import { AbilitySelectorComponent } from './components/ability-selector/ability-selector.component';
import { CompanySelectorComponent } from './components/company-selector/company-selector.component';
import { GameSelectorComponent } from './components/game-selector/game-selector.component';
import { DateTimeSelectorComponent } from './components/date-time-selector/date-time-selector.component';
import { DateSelectorComponent } from './components/date-selector/date-selector.component';
@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        SharedMaterialModule,
        MatCardModule,
        MomentModule,
        AngularResizeEventModule,
        TranslateModule,
        RouterModule,
        QuillModule.forRoot(),
        NgMultiSelectDropDownModule.forRoot(),
    ],
    declarations: [
        IfRoleDirective,
        WaitAndBlockDirective,
        CohortSelectComponent,
        EmailTemplateSelectComponent,
        StockMediaComponent,
        SafePipe,
        BannerSpotSelectComponent,
        BannerSelectComponent,
        PriceSetSelectComponent,
        FileUploadComponent,
        ConfirmDialogComponent,
        StockSelectComponent,
        HtmlPreviewComponent,
        TimerSelectComponent,
        TextListInputComponent,
        CohortInfoDialogComponent,
        BannerCollectionTypeSelectComponent,
        TagsSelectComponent,
        AutoSelectComponent,
        TextTagsSelectComponent,
        DateTimeComponent,
        StockPriceClassSelectComponent,
        TildaPageSelectComponent,
        EditorComponent,
        ProductSelectComponent,
        CourseAccessSelectComponent,
        DatePeriodSelectorComponent,
        ProductsSelectFromListComponent,
        MonthAndYearSelector,
        CohortFiltersComponent,
        CohortsSelectFromListComponent,
        OrdersTableComponent,
        GetDataBySplittingRequestDialogComponent,
        AbilitySelectorComponent,
        GameSelectorComponent,
        DateTimeSelectorComponent,
        DateSelectorComponent,
        CompanySelectorComponent,
    ],
    exports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        SharedMaterialModule,
        MomentModule,
        IfRoleDirective,
        WaitAndBlockDirective,
        CohortSelectComponent,
        PriceSetSelectComponent,
        EmailTemplateSelectComponent,
        SafePipe,
        StockMediaComponent,
        BannerSpotSelectComponent,
        BannerSelectComponent,
        FileUploadComponent,
        StockSelectComponent,
        HtmlPreviewComponent,
        TimerSelectComponent,
        TextListInputComponent,
        TranslateModule,
        BannerCollectionTypeSelectComponent,
        TagsSelectComponent,
        AutoSelectComponent,
        TextTagsSelectComponent,
        DateTimeComponent,
        StockPriceClassSelectComponent,
        TildaPageSelectComponent,
        EditorComponent,
        ProductSelectComponent,
        CourseAccessSelectComponent,
        DatePeriodSelectorComponent,
        ProductsSelectFromListComponent,
        MonthAndYearSelector,
        CohortsSelectFromListComponent,
        NgMultiSelectDropDownModule,
        OrdersTableComponent,
        AbilitySelectorComponent,
        GameSelectorComponent,
        DateTimeSelectorComponent,
        DateSelectorComponent,
        CompanySelectorComponent,
    ],
    providers: [cohortFilterProviderProvider],
})
export class SharedModule {}
