import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { InternalApiService } from '@app/shared/api/internal-api.service';
import { Product } from '@app/shared/models/internal/product';
import { isEqual } from 'lodash';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
@Component({
    selector: 'app-products-select-from-list',
    templateUrl: './products-select-from-list.component.html',
    styleUrls: ['./products-select-from-list.component.scss'],
})
export class ProductsSelectFromListComponent implements OnChanges {
    @Input() productsList: string[] = [];
    @Output() updatedSelectedProducts = new EventEmitter<string[]>();

    dropdownSettings: IDropdownSettings = {
        singleSelection: false,
        idField: 'item_id',
        textField: 'item_text',
        selectAllText: 'Выбрать все',
        unSelectAllText: 'Убрать все',
        searchPlaceholderText: 'Поиск',
        noFilteredDataAvailablePlaceholderText: 'Не найдено',
        allowSearchFilter: true,
        itemsShowLimit: 6,
    };

    dropdownList = [];
    selectedItems = [];
    prevSevectedItems = [];

    allProducts: Product[];

    constructor(private internalApiService: InternalApiService, private changeDetection: ChangeDetectorRef) {}

    ngOnChanges() {
        this.updateValue();
    }

    async updateValue() {
        this.dropdownList = await this.getData(this.productsList);
        this.selectedItems = [...this.dropdownList];
        this.prevSevectedItems = [...this.selectedItems];
        this.changeDetection.detectChanges();
    }

    async getData(products: string[] | null) {
        const data = [];

        for (let i = 0; i < products?.length; i++) {
            let item = { 
                item_text: await this.internalApiService.getProductName(products[i]), 
                item_id: products[i] 
            };
            data.push(item);
        }
        return data;
    }

    onDropDownClose() {
        if (!isEqual(this.prevSevectedItems, this.selectedItems)) {
            let selected = [];
            for (let i = 0; i < this.selectedItems.length; i++) {
                selected.push(this.selectedItems[i].item_id);
            }
            this.updatedSelectedProducts.emit(selected);
            this.prevSevectedItems = [...this.selectedItems];
        }
    }
}
