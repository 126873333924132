import { Filter } from './filter';

export class DateRange extends Filter<{ from: string; to: string }> {
    controlType = 'date_range';
    value = { from: null, to: null };

    isEmpty(): boolean {
        return !this.value.from && !this.value.to;
    }
}
