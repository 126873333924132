<mat-form-field appearance="outline" class="date-time-select">
    <mat-label>{{ label }}</mat-label>
    <input
        (ngModelChange)="updateValue($event)"
        [(ngModel)]="value"
        matInput
        [ngxMatDatetimePicker]="picker"
        type="datetime"
        [min]="min"
        #datetime="ngModel"
        [required]="required"
    />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <ngx-mat-datetime-picker #picker></ngx-mat-datetime-picker>

    <mat-error *ngIf="datetime.hasError('matDatetimePickerMin')">
        Дата меньше минимальной
    </mat-error>
</mat-form-field>
