import { ErrorHandler, LOCALE_ID, NgModule, Injectable } from '@angular/core';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_DATE_LOCALE, MatCommonModule } from '@angular/material/core';
import { MatIconRegistry } from '@angular/material/icon';
import { AppRoutingModule } from './app.routing';
import { LayoutsModule } from './layouts/layouts.module';
import { AppComponent } from './app.component';
import 'hammerjs';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import * as Sentry from '@sentry/browser';
import { environment } from '@env/environment';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

registerLocaleData(localeRu);
Sentry.init({ dsn: environment.sentryDsn });

@Injectable()
export class RavenErrorHandler extends ErrorHandler {
    handleError(err: any): void {
        super.handleError(err);
        Sentry.captureException(err);
    }
}

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        MatCommonModule,
        AppRoutingModule,
        LayoutsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            defaultLanguage: 'ru',
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
    ],
    bootstrap: [AppComponent],
    providers: [
        { provide: LOCALE_ID, useValue: 'ru' },
        { provide: MAT_DATE_LOCALE, useValue: 'ru-RU' },
        { provide: ErrorHandler, useClass: RavenErrorHandler },
    ],
})
export class AppModule {
    constructor(private matIconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {
        // Register Font Awesome
        matIconRegistry.registerFontClassAlias('fontawesome', 'fa');

        // Register layout icon SVGs
        matIconRegistry.addSvgIcon(
            'classic',
            sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/classic.svg'),
        );

        matIconRegistry.addSvgIcon(
            'toolbar',
            sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/toolbar.svg'),
        );

        matIconRegistry.addSvgIcon(
            'compact',
            sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/compact.svg'),
        );

        matIconRegistry.addSvgIcon('boxed', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/boxed.svg'));

        matIconRegistry.addSvgIcon('funky', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/funky.svg'));

        matIconRegistry.addSvgIcon(
            'tabbed',
            sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/tabbed.svg'),
        );
    }
}
