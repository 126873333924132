<mat-form-field class="stock-select" appearance="outline">
    <mat-label>Акция</mat-label>

    <input type="text" matInput [matAutocomplete]="auto" [formControl]="inputControl" [required]="required" />

    <mat-autocomplete (optionSelected)="onOptionSelect($event)" #auto="matAutocomplete">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
            {{ option.name }}
        </mat-option>
    </mat-autocomplete>

    <button mat-icon-button matSuffix (click)="clear()" *ngIf="inputControl.value">
        <mat-icon>clear</mat-icon>
    </button>

    <mat-error *ngIf="inputControl.hasError('required')">
        Это поле обязательно для заполнения
    </mat-error>

    <mat-error *ngIf="inputControl.hasError('notFound')">
        Акция не найдена
    </mat-error>
</mat-form-field>
