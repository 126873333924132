import { Filter } from './filter';

export class YesOrNoWithSingleOption extends Filter<{ has: boolean; option: string | number }> {
    controlType = 'yes_or_no_with_single_option';
    value = { has: null, option: null };

    isEmpty(): boolean {
        return this.value === null || this.value.has === null || this.value.option === null;
    }
}
