import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { cloneDeep, isEqual } from 'lodash';
import moment, { Moment } from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';

@Component({
    selector: 'app-date-period-selector',
    templateUrl: './date-period-selector.component.html',
    styleUrls: ['./date-period-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: DatePeriodSelectorComponent,
            multi: true,
        },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    ],
})
export class DatePeriodSelectorComponent implements OnChanges, ControlValueAccessor {
    @Input() ngModel: { from: Moment; to: Moment } = { from: null, to: null };
    @Input()
    selectedPeriod = 30;

    @Output()
    selected: EventEmitter<{ from: string; to: string }> = new EventEmitter();

    previousSelected = { to: null, from: null };

    onChange;

    constructor() {}

    ngOnChanges(): void {
        if (!this.ngModel || !this.ngModel.from || !this.ngModel.to) {
            this.ngModel.to = moment().startOf('day');
            this.ngModel.from = moment()
                .startOf('day')
                .add(-this.selectedPeriod, 'days');

            this.emit();
        }
    }

    getFormattedValue() {
        return {
            from: this.ngModel.from.format('YYYY-MM-DD'),
            to: this.ngModel.to.format('YYYY-MM-DD'),
        };
    }

    emit() {
        const emit = this.getFormattedValue();

        if (!isEqual(emit, this.previousSelected)) {
            this.selected.emit(emit);

            this.previousSelected = cloneDeep(emit);
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        // not use
    }

    setDisabledState(isDisabled: boolean): void {
        // not use
    }

    writeValue(period: { from: Moment; to: Moment }): void {
        if (period) {
            this.ngModel = period;
        }
    }
}
