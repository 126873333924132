import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';

@Component({
    selector: 'app-date-time',
    templateUrl: './date-time.component.html',
    styleUrls: ['./date-time.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateTimeComponent),
            multi: true,
        },
    ],
})
export class DateTimeComponent implements ControlValueAccessor {
    @Input() label: string;

    _onChange: (value: string | null) => void;
    value: string;

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {}

    writeValue(value: string): void {
        this.value = value;
    }

    clear() {
        this.value = null;
        this.updateValue(null);
    }

    updateValue(date) {
        if (moment(date).isValid()) {
            this.value = date;
            date = moment(date).format('YYYY-MM-DD HH:mm:ss');
        } else {
            date = null;
        }

        this._onChange(date);
    }
}
