import { Component, Input } from '@angular/core';
import { Node } from '@app/shared/models/cohort/node';

@Component({
    selector: 'app-cohort-filters',
    templateUrl: './cohort-filters.component.html',
    styleUrls: ['./cohort-filters.component.scss'],
})
export class CohortFiltersComponent {
    @Input() node: Node;

    constructor() {}

    getName(options, value) {
        return options.filter(x => x.value === value)[0]?.key || value;
    }
}
