// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const url = window.location.hostname;

export const environment = {
    production: true,
    sentryDsn: null,
    uploadServer: `http://upload.${url}`,

    /**
     * RPC config
     */

    rpc: [
        {
            name: 'xhr',
            connector: 'xhr',
            options: {
                address: [`http://api.${url}/v1`],
            },
        },
    ],

    /**
     * Storage config
     */

    storage: {
        adapter: 'local',
    },

    /**
     * Auth config
     */

    auth: {
        token_name: 'token',
        providers: [
            {
                name: 'credential',
                type: 'credential',
                params: {},
            },
        ],
    },

    /**
     * Security config
     */

    security: {
        roles: {
            user: [],
            anonymous: ['ROLE_ANONYMOUS'],
        },
    },

    /**
     * Cache
     */

    cache: {
        memory: {
            lifetime: 60,
        },
    },
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
