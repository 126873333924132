import { Component, OnInit, forwardRef, Output, EventEmitter, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, Validator } from '@angular/forms';
@Component({
    selector: 'app-text-list-input',
    templateUrl: './text-list-input.component.html',
    styleUrls: ['./text-list-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TextListInputComponent),
            multi: true,
        },
    ],
})
export class TextListInputComponent implements OnInit, ControlValueAccessor {
    @Output() change = new EventEmitter<string[]>();
    @Input() label: string;

    value: string[] = [];
    rawValue: string;
    isDisabled = false;

    _onChange = (_: any) => {};
    _onTouched = () => {};
    _onValidatorChange = () => {};

    constructor() {}

    ngOnInit() {}

    onChange() {
        this.writeValue(this.rawValue);
        this._onChange(this.value);
        this.change.emit(this.value);
    }

    setDisabledState?(isDisabled: boolean) {
        this.isDisabled = isDisabled;
    }

    writeValue(str: string) {
        if (!str) {
            this.value = [];
            this.rawValue = null;
            return;
        }

        const regex = /[^,\s]+/gm;
        let regexResult;
        const newValue = [];
        while ((regexResult = regex.exec(str)) !== null) {
            if (regexResult.index === regex.lastIndex) {
                regex.lastIndex++;
            }

            regexResult.forEach(match => {
                newValue.push(match);
            });
        }

        this.value = newValue;
        this.rawValue = newValue.join(', ');
    }

    registerOnChange(fn) {
        this._onChange = fn;
    }

    registerOnTouched(fn) {
        this._onTouched = fn;
    }
}
