import { Filter } from './filter';

export class DateRangeWithOption extends Filter<{ from: string; to: string; option: string }> {
    controlType = 'date_range_with_option';
    value = { from: null, to: null, option: null };

    isEmpty(): boolean {
        return !this.value.option || (this.value.option && !this.value.from && !this.value.to);
    }
}
