export function block(element: HTMLElement, promise: Promise<any>): Promise<any> {
    const waiter = document.createElement('div');
    waiter.classList.add('waiter');
    waiter.classList.add('waiter--block');

    element.appendChild(waiter);
    element.classList.add('waited');

    promise.then(() => {
        element.classList.remove('waited');
        waiter.remove();
    });

    return promise;
}
