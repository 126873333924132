import { Injectable, NgZone } from '@angular/core';
import { RpcClient } from './client';
import { RpcXhrConnector } from './connector/xhr.connector';
import { LocalConnector } from './connector/local.connector';
import { environment } from '@env/environment';
import { TokenSubject } from '@app/shared/auth/observable/token.observable';

@Injectable({
    providedIn: 'root',
})
export class Rpc {
    /**
     * Rpc client.
     */
    private clients: RpcClient[] = [];

    public constructor(private authTokenObservable: TokenSubject) {
        environment.rpc.forEach(item => {
            let connector;
            switch (item.connector) {
                case 'xhr':
                    connector = new RpcXhrConnector(<any>item.options);
                    break;
                case 'local':
                    connector = new LocalConnector(<any>item.options);
                    break;
                default:
                    throw Error('No support connector.');
            }

            this.clients.push(new RpcClient(connector, item.name));
        });

        this.authTokenObservable.subscribe(token => {
            this.setToken(token);
        });
    }

    /**
     * Call method.
     */
    call(method: string, params?: any, clientName?): Promise<any> {
        return this.getClient(clientName).call(method, params);
    }

    /**
     * Set token.
     */
    setToken(token: string): void {
        return this.clients.forEach(client => client.setToken(token));
    }

    /**
     * Get rpc client
     */
    getClient(name?): RpcClient {
        let client = this.clients[0];

        if (name) {
            this.clients.forEach(item => {
                if (item.getName() === name) {
                    client = item;
                }
            });
        }

        return client;
    }

    /**
     * Get rpc clients
     */
    getClients(): RpcClient[] {
        return this.clients;
    }
}
