import { AfterViewInit, Component, ElementRef, Input, ViewChildren, QueryList } from '@angular/core';
import { ResizedEvent } from 'angular-resize-event';

@Component({
    selector: 'app-html-preview',
    templateUrl: './html-preview.component.html',
    styleUrls: ['./html-preview.component.scss'],
})
export class HtmlPreviewComponent implements AfterViewInit {
    @Input() html: string;
    @Input() media: 'responsive' | 'desktop' | 'tablet' | 'mobile';
    @Input() scale = 100;

    devices = [
        {
            name: 'Адаптивый',
            width: '100%',
        },
        {
            name: 'Iphone 5/SE',
            width: '320px',
        },
        {
            name: 'Iphone 6/7/8 Plus',
            width: '414px',
        },
        {
            name: 'Iphone X',
            width: '375px',
        },
        {
            name: 'iPad',
            width: '768px',
        },
        {
            name: 'Desktop',
            width: '1190px',
        },
    ];
    currentDevice = this.devices.find(x => x.name === 'Iphone 5/SE');
    currentWidth = this.currentDevice.width;
    iframeHeight;

    @ViewChildren('iframe')
    iframes: QueryList<ElementRef>;

    onResized(event: ResizedEvent) {
        const newWidth = event.newRect.width + 'px';
        this.currentDevice = this.devices.find(x => x.width === newWidth) || this.devices[0];
        this.currentWidth = newWidth;
        this.updateIframeHeight(this.iframes.toArray()[0]);
    }

    selectDevice(device: string) {
        this.currentDevice = this.devices.find(x => x.name === device);
        this.currentWidth = this.currentDevice.width;
        this.updateIframeHeight(this.iframes.toArray()[0]);
    }

    ngAfterViewInit() {
        this.iframes.forEach(iframe => (iframe.nativeElement.onload = () => this.updateIframeHeight(iframe)));
    }

    updateIframeHeight(iframe: ElementRef) {
        const body = iframe.nativeElement.contentWindow.document.body;
        if (body) {
            this.iframeHeight = body.scrollHeight + 40;
        }
    }
}
