import { Injectable } from '@angular/core';
import { Rpc } from '@app/shared/rpc';
import { PaginationList } from '@models/pagination-list';
import { Course } from '@models/course/course';
import { CourseLesson } from '@models/course/course-lesson';
import { CourseModule } from '@models/course/course-module';
import { CourseStructure } from '@models/course/course-structure';
import { PartType } from '@models/course/part-type';
import { LessonPart } from '@models/course/lesson-part';
import { LessonPartHomework } from '@models/course/lesson-part-homework';
import { LessonPartAudio } from '@models/course/lesson-part-audio';
import { LessonPartLongread } from '@models/course/lesson-part-longread';
import { LessonPartTest } from '@models/course/lesson-part-test';
import { LessonPartVideo } from '@models/course/lesson-part-video';
import { LessonPartCollection } from '@models/course/lesson-part-collection';
import { CourseAccess } from '@models/course/course-access';
import { UserCourseAccess } from '@models/course/user-course-access';

@Injectable({
    providedIn: 'root',
})
export class CourseApiService {
    constructor(protected rpc: Rpc) {}

    getAllCourses(onlyPublic: boolean): Promise<Course[]> {
        return this.rpc.call('course.getAll', [onlyPublic]);
    }

    getCourse(id: number): Promise<Course> {
        return this.rpc.call('course.getById', {
            id,
        });
    }

    getFullCourse(id: number): Promise<Course> {
        return this.rpc.call('course.getFull', {
            id,
        });
    }

    getStructureForAdminById(id: number): Promise<CourseStructure> {
        return this.rpc.call('course.getStructureForAdminById', {
            id,
        });
    }

    getCourseList({
        pageIndex,
        pageSize,
        search,
    }: {
        pageIndex: number;
        pageSize: number;
        search: string;
    }): Promise<PaginationList<Course>> {
        return this.rpc.call('course.list', {
            pageIndex,
            pageSize,
            search,
        });
    }

    updateCourse(course: Course): Promise<Course> {
        return this.rpc.call('course.update', course);
    }

    createCourse(course: Course): Promise<Course> {
        return this.rpc.call('course.create', course);
    }

    getModule(id: number): Promise<CourseModule> {
        return this.rpc.call('course.module.getById', {
            id,
        });
    }

    updateModule({
        id,
        name,
        description,
        isPublic,
    }: {
        id: number;
        name?: string;
        description?: string;
        isPublic?: boolean;
    }): Promise<CourseModule> {
        return this.rpc.call('course.module.update', {
            id,
            name,
            description,
            isPublic,
        });
    }

    createModule({
        courseId,
        name,
        description,
        isPublic,
    }: {
        courseId: number;
        name: string;
        description?: string;
        isPublic?: boolean;
    }): Promise<CourseModule> {
        return this.rpc.call('course.module.create', {
            courseId,
            name,
            description,
            isPublic,
        });
    }

    deleteModule(id: number): Promise<boolean> {
        return this.rpc.call('course.module.delete', {
            id,
        });
    }

    getAllModules(courseId: number, onlyPublic?: boolean): Promise<CourseModule[]> {
        return this.rpc.call('course.module.getAllByCourse', {
            courseId,
            onlyPublic,
        });
    }

    getLesson(id: number): Promise<CourseLesson> {
        return this.rpc.call('course.lesson.getById', {
            id,
        });
    }

    updateLesson(lesson: CourseLesson): Promise<CourseLesson> {
        return this.rpc.call('course.lesson.update', lesson);
    }

    createLesson({
        moduleId,
        name,
        description,
        isPublic,
    }: {
        moduleId: number;
        name: string;
        description?: string;
        isPublic?: boolean;
    }): Promise<CourseLesson> {
        return this.rpc.call('course.lesson.create', {
            moduleId,
            name,
            description,
            isPublic,
        });
    }

    deleteLesson(id: number): Promise<boolean> {
        return this.rpc.call('course.lesson.delete', {
            id,
        });
    }

    getAllLessons(moduleId: number, onlyPublic?: boolean): Promise<CourseLesson[]> {
        return this.rpc.call('course.lesson.getAllByModule', {
            moduleId,
            onlyPublic,
        });
    }

    changeLessonPosition(id: number, position: number): Promise<boolean> {
        return this.rpc.call('course.lesson.changePosition', {
            id,
            position,
        });
    }

    getPart<T extends LessonPart>(id: number): Promise<T> {
        return this.rpc.call('course.part.getById', {
            id,
        });
    }

    updatePart(
        lessonPart:
            | LessonPartHomework
            | LessonPartAudio
            | LessonPartLongread
            | LessonPartTest
            | LessonPartVideo
            | LessonPartCollection,
    ): Promise<LessonPart> {
        return this.rpc.call('course.part.update', {
            id: lessonPart.id,
            name: lessonPart.name,
            title: lessonPart.title,
            description: lessonPart.description,
            comment: lessonPart.comment,
            accessLevel: lessonPart.accessLevel,
            params: CourseApiService.createParams(lessonPart),
        });
    }

    createPart(
        lessonPart:
            | LessonPartHomework
            | LessonPartAudio
            | LessonPartLongread
            | LessonPartTest
            | LessonPartVideo
            | LessonPartCollection,
    ): Promise<LessonPart> {
        const data = {
            lessonId: lessonPart.lessonId,
            type: lessonPart.type,
            name: lessonPart.name,
            title: lessonPart.title,
            description: lessonPart.description,
            comment: lessonPart.comment,
            accessLevel: lessonPart.accessLevel,
            params: CourseApiService.createParams(lessonPart),
        };

        if (lessonPart.parentId) {
            data['parentId'] = lessonPart.parentId;
        }

        return this.rpc.call('course.part.create', data);
    }

    private static createParams(
        lessonPart:
            | LessonPartHomework
            | LessonPartAudio
            | LessonPartLongread
            | LessonPartTest
            | LessonPartVideo
            | LessonPartCollection,
    ) {
        switch (lessonPart.type) {
            case PartType.Homework: {
                return {
                    question: lessonPart.question,
                    video: lessonPart.video,
                    answers: lessonPart.answers,
                };
            }
            case PartType.Audio: {
                return {
                    url: lessonPart.url,
                    text: lessonPart.text,
                    imageUrl: lessonPart.imageUrl,
                };
            }
            case PartType.Longread: {
                return {
                    content: lessonPart.content,
                };
            }
            case PartType.Test: {
                return {
                    testId: lessonPart.testId,
                    attempts: lessonPart.attempts,
                };
            }
            case PartType.Video: {
                return {
                    url: lessonPart.url,
                    text: lessonPart.text,
                };
            }
            case PartType.Collection: {
                return {
                    children: lessonPart.children,
                };
            }
            default:
                throw new Error(`lessonPart ${lessonPart} doesn't exist`);
        }
    }

    deletePart(id: number): Promise<boolean> {
        return this.rpc.call('course.part.delete', {
            id,
        });
    }

    getAllParts(lessonId: number, onlyPublic?: boolean): Promise<LessonPart[]> {
        return this.rpc.call('course.part.getAllByLesson', {
            lessonId,
            onlyPublic,
        });
    }

    changePartPosition(id: number, position: number): Promise<Course> {
        return this.rpc.call('course.part.changePosition', {
            id,
            position,
        });
    }

    getAllByCourseAccess(courseId: number): Promise<CourseAccess[]> {
        return this.rpc.call('course.access.getAllByCourse', {
            courseId,
        });
    }

    getAllAccess(): Promise<CourseAccess[]> {
        return this.rpc.call('course.access.getAll');
    }

    createAccess(courseAccess: CourseAccess): Promise<CourseAccess> {
        return this.rpc.call('course.access.create', courseAccess);
    }

    updateAccess(courseAccess: CourseAccess): Promise<CourseAccess> {
        return this.rpc.call('course.access.update', {
            id: courseAccess.id,
            name: courseAccess.name,
            level: courseAccess.level,
            tariff: courseAccess.tariff,
            description: courseAccess.description,
        });
    }

    deleteAccess(id: number): Promise<boolean> {
        return this.rpc.call('course.access.delete', {
            id,
        });
    }

    getAllAccessByUserId(userId: number): Promise<UserCourseAccess[]> {
        return this.rpc.call('course.userAccess.getAllByUserId', {
            userId,
        });
    }

    deleteUserAccess(id: number): Promise<UserCourseAccess[]> {
        return this.rpc.call('course.userAccess.delete', [id]);
    }

    createUserAccess(accessId: number, userId: number): Promise<UserCourseAccess> {
        return this.rpc.call('course.userAccess.create', { accessId, userId });
    }
}
