import { Filter } from './filter';

export class YesOrNoWithTags extends Filter<{ has: boolean; options: string[] }> {
    controlType = 'yes_or_no_with_tags';
    value = { has: null, options: null };
    pattern: RegExp;

    constructor(
        name: string,
        options: {
            value?: { has: boolean; options: string[] };
            options?: { key: string; value: string | number }[];
            pattern?: RegExp;
        } = {},
    ) {
        super(name, options);
        this.pattern = options.pattern || null;
    }

    isEmpty(): boolean {
        return (
            this.value === null ||
            this.value.has === null ||
            this.value.options === null ||
            this.value.options.length === 0
        );
    }
}
