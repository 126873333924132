import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { LayoutClassicComponent } from './layouts/layout-classic/layout-classic.component';
import { LayoutTabbedComponent } from './layouts/layout-tabbed/layout-tabbed.component';
import { LayoutBoxedComponent } from './layouts/layout-boxed/layout-boxed.component';
import { LayoutCompactComponent } from './layouts/layout-compact/layout-compact.component';
import { LayoutToolbarComponent } from './layouts/layout-toolbar/layout-toolbar.component';
import { LayoutFunkyComponent } from './layouts/layout-funky/layout-funky.component';
import { AuthUserActivate } from '@app/shared/auth';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'signin',
        pathMatch: 'full',
    },
    {
        path: '',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    },
    {
        path: '',
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
    },
    {
        path: '',
        component: LayoutClassicComponent,
        canActivate: [AuthUserActivate],
        children: [
            {
                path: 'dashboard',
                loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
            },
            {
                path: 'emails',
                loadChildren: () => import('./emails/emails.module').then(m => m.EmailsModule),
            },
            {
                path: 'banner',
                loadChildren: () => import('./banner/banner.module').then(m => m.BannerModule),
            },
            {
                path: 'admin',
                loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
            },
            {
                path: 'cohort',
                loadChildren: () => import('./cohort/cohort.module').then(m => m.CohortModule),
            },
            {
                path: 'stocks',
                loadChildren: () => import('./stocks/stocks.module').then(m => m.StocksModule),
            },
            {
                path: 'uploads',
                loadChildren: () => import('./uploads/uploads.module').then(m => m.UploadsModule),
            },
            {
                path: 'settings',
                loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
            },
            {
                path: 'payment',
                loadChildren: () => import('./payment/payment.module').then(m => m.PaymentModule),
            },
            {
                path: 'notification',
                loadChildren: () => import('./notification/notification.module').then(m => m.NotificationModule),
            },
            {
                path: 'audit',
                loadChildren: () => import('./audit/audit.module').then(m => m.AuditModule),
            },
            {
                path: 'course',
                loadChildren: () => import('./course/course.module').then(m => m.CourseModule),
            },
            {
                path: 'report',
                loadChildren: () => import('./report/report.module').then(m => m.ReportModule),
            },
            {
                path: 'orders',
                loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule),
            },
            {
                path: 'user',
                loadChildren: () => import('./user/user.module').then(m => m.UserModule),
            },
            {
                path: 'page',
                loadChildren: () => import('./page/page.module').then(m => m.PageModule),
            },
            {
                path: 'tilda',
                loadChildren: () => import('./tilda/tilda.module').then(m => m.TildaModule),
            },
            {
                path: 'game',
                loadChildren: () => import('./game/game.module').then(m => m.GameModule),
            },
            {
                path: 'bpiability',
                loadChildren: () => import('./bpiability/bpiability.module').then(m => m.BpiabilityModule),
            },
            {
                path: 'competence',
                loadChildren: () => import('./competence/competence.module').then(m => m.CompetenceModule),
            },
            {
                path: 'company',
                loadChildren: () => import('./company/company.module').then(m => m.CompanyModule),
            },
            {
                path: 'challenge',
                loadChildren: () => import('./challenge/challenge.module').then(m => m.ChallengeModule),
            }
        ],
    },
    {
        path: '**',
        redirectTo: '/404',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule {
    constructor(private router: Router) {
        /**
         * This allows us to switch layouts using the layout switcher.
         */
        const baseRoute = this.router.config.find(route => route.data !== undefined && route.data.base === true);
        switch (sessionStorage.getItem('portal-layout')) {
            case 'toolbar':
                baseRoute.component = LayoutToolbarComponent;
                break;
            case 'compact':
                baseRoute.component = LayoutCompactComponent;
                break;
            case 'boxed':
                baseRoute.component = LayoutBoxedComponent;
                break;
            case 'funky':
                baseRoute.component = LayoutFunkyComponent;
                break;
            case 'tabbed':
                baseRoute.component = LayoutTabbedComponent;
                break;
            default:
                // Do nothing.
                break;
        }
    }
}
