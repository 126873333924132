import { Component, OnInit, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormControl, ControlValueAccessor } from '@angular/forms';
import { PriceApiService } from '@app/shared/api/price-api.service';
import { PriceSet } from '@models/price/priceSet';

@Component({
    selector: 'app-price-set-select',
    templateUrl: './price-set-select.component.html',
    styleUrls: ['./price-set-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: PriceSetSelectComponent,
            multi: true,
        },
    ],
})
export class PriceSetSelectComponent implements OnInit, ControlValueAccessor {
    selectControl = new UntypedFormControl();
    onChange: Function;
    options: PriceSet[] = [];
    value = null;

    @Input()
    required = false;

    @Input() controlField: keyof PriceSet = 'slug';

    constructor(private priceApiService: PriceApiService) {}

    async ngOnInit() {
        this.options = await this.priceApiService.getPriceSet();
    }

    selectValue(value: number | null) {
        if (this.onChange) {
            this.onChange(value);
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {}

    setDisabledState(isDisabled: boolean): void {}

    writeValue(value: any): void {
        this.value = value;
    }
}
