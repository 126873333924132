<mat-form-field class="banner-type-select" appearance="outline">
    <mat-label>
        Баннер
    </mat-label>
    <mat-select
        [value]="value"
        (selectionChange)="selectValue($event.value)"
        [disabled]="disabled"
        [compareWith]="compare"
    >
        <mat-option [value]="null">Не выбрано</mat-option>
        <mat-option *ngFor="let banner of getBanners()" [value]="typeModel ? banner : banner.slug">
            {{ banner.name }}
        </mat-option>
    </mat-select>
</mat-form-field>
