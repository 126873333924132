import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'app-get-data-by-splitting-request-dialog',
    templateUrl: './get-data-by-splitting-request-dialog.component.html',
    styleUrls: ['./get-data-by-splitting-request-dialog.component.scss'],
})
export class GetDataBySplittingRequestDialogComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<GetDataBySplittingRequestDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: { func: any; splittingCount: number },
    ) {}

    async ngOnInit() {
        this.dialogRef.disableClose = true;

        this.getData().subscribe(resultData => {
            this.dialogRef.close(resultData);
        });
    }

    getData() {
        const resultData: Promise<any>[] = [];
        for (let i = 0; i < this.data.splittingCount; i++) {
            resultData[i] = this.data.func(i);
        }
        return forkJoin(resultData);
    }
}
