import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';

@Component({
    selector: 'app-tags-select',
    templateUrl: './tags-select.component.html',
    styleUrls: ['./tags-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TagsSelectComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagsSelectComponent implements OnInit, ControlValueAccessor {
    @Input() placeholder: string = 'type...';
    @Input() label: string;
    @Input() options: { key: string; value: string | number }[];
    @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;
    @Output() valueChange = new EventEmitter<(string | number)[]>();

    selected: (string | number)[] = [];
    notSelectedOptions: Observable<{ key: string; value: string | number }[]>;
    separatorKeysCodes = [ENTER, COMMA];
    tagCtrl = new UntypedFormControl();

    _onChange: (selected: (string | number)[] | null) => void;
    _onTouched = () => {};

    constructor(private cd: ChangeDetectorRef) {
        this.notSelectedOptions = this.tagCtrl.valueChanges.pipe(
            startWith(''),
            map((tag: string | number | null) => {
                if (!tag) {
                    return this.options.filter(f => !this.selected.includes(f.value));
                }

                return this.options.filter(
                    f =>
                        !this.selected.map(s => (s + '').toLowerCase()).includes((f.value + '').toLowerCase()) &&
                        ((f.value + '').toLowerCase().includes((tag + '').toLowerCase()) ||
                            (f.key + '').toLowerCase().includes((tag + '').toLowerCase())),
                );
            }),
            tap(_ => this.cd.markForCheck()),
        );
    }

    ngOnInit() {}

    writeValue(value: string[]) {
        this.selected = value || [];
        this.tagCtrl.setValue(null);
    }

    registerOnChange(fn) {
        this._onChange = fn;
    }

    registerOnTouched(fn) {
        this._onTouched = fn;
    }

    remove(value: string): void {
        this.selected.splice(this.selected.indexOf(value), 1);
        this._onChange(this.selected.length ? this.selected : null);
        this.valueChange.emit(this.selected);
    }

    optionSelected(event: MatAutocompleteSelectedEvent): void {
        this.selected.push(event.option.value);

        this.tagInput.nativeElement.value = '';
        this.tagInput.nativeElement.blur();
        setTimeout(() => {
            this.tagInput.nativeElement.focus();
        }, 0);
        this.tagCtrl.setValue(null);

        this._onChange(this.selected.length ? this.selected : null);
        this.valueChange.emit(this.selected);
    }

    get selectedOptions() {
        return this.options
            .filter(f => this.selected.includes(f.value))
            .sort((a, b) => {
                return this.selected.indexOf(a.value) - this.selected.indexOf(b.value);
            });
    }
}
