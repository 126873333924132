import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CompetenceInternalApiService } from '@app/shared/api/competence-internal-api.service';
import { Ability } from '@app/shared/models/competence/ability';

@Component({
    selector: 'app-ability-selector',
    templateUrl: './ability-selector.component.html',
    styleUrls: ['./ability-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: AbilitySelectorComponent,
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AbilitySelectorComponent implements OnInit, ControlValueAccessor {
    @Input() required = false;

    onChange: Function;
    value: number;
    abilities: Ability[];

    constructor(
        private competenceInternalApiService: CompetenceInternalApiService,
        private changeDetectorRef: ChangeDetectorRef,
    ) {}

    async ngOnInit() {
        this.abilities = await this.competenceInternalApiService.getAllAbilities(true);
        this.changeDetectorRef.detectChanges();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {}

    setDisabledState(isDisabled: boolean): void {}

    writeValue(value: number): void {
        this.value = value;
    }

    change() {
        if (this.onChange) {
            this.onChange(this.value);
        }
    }
}
