import { Component, OnInit, Input } from '@angular/core';
import { Media } from '@app/shared/models/stock/media';
import { StocksApiService } from '@app/shared/api/stocks-api.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-stock-media',
    templateUrl: './stock-media.component.html',
    styleUrls: ['./stock-media.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: StockMediaComponent,
            multi: true,
        },
    ],
})
export class StockMediaComponent implements OnInit, ControlValueAccessor {
    onChange: Function;
    media: Media[] = [];

    constructor(private stockApiService: StocksApiService) {}

    ngOnInit() {}

    deleteMedia(media, i) {
        media.splice(i, 1);
    }

    addMedia(type: string) {
        switch (type) {
            case 'banner':
                this.media.push({
                    spot: null,
                    slug: null,
                    type: 'banner',
                });
                break;
        }

        if (this.onChange) {
            this.onChange(this.media);
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {}

    setDisabledState(isDisabled: boolean): void {}

    writeValue(value: any): void {
        this.media = value || [];
    }
}
