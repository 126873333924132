import { StorageAdapterInterface } from './adapter/storage.interface';
import { StorageAdapterLocal } from './adapter/local.storage';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root',
})
export class Storage implements StorageAdapterInterface {
    /**
     * Storage adapter.
     */
    private adapter: StorageAdapterInterface;

    public constructor() {
        switch (environment.storage.adapter) {
            case 'local':
                this.adapter = new StorageAdapterLocal();
                break;
            default:
                throw 'No support connector.';
        }
    }

    /**
     * Set or update item from storage.
     *
     * @param keyName
     * @param value
     */
    setItem(keyName: string, value: any): void {
        return this.adapter.setItem(keyName, value);
    }

    /**
     * Get item from storage.
     *
     * @param keyName
     */
    getItem(keyName: string): any | null {
        return this.adapter.getItem(keyName);
    }

    /**
     * Remove item from storage.
     *
     * @param keyName
     */
    removeItem(keyName: string): void {
        return this.adapter.removeItem(keyName);
    }

    /**
     * Clear all in storage.
     */
    clear(): void {
        return this.adapter.clear();
    }

    /**
     * Is storage support.
     */
    isSupport(): boolean {
        return this.adapter.isSupport();
    }
}
