<ng-container *ngIf="(orders || []).length !== 0; else emptyOrders">
    <button *ngIf="isEnableExcelExport" mat-raised-button color="primary" (click)="exportToExcel()">Export в excel</button>
    <mat-table
        [appWaitAndBlock]="isWaiting"
        [dataSource]="dataSource"
        style="overflow: scroll; width: 100%;"
        class="orders-list"
    >
        <ng-container matColumnDef="number">
            <mat-header-cell *matHeaderCellDef>Номер заказа</mat-header-cell>
            <mat-cell *matCellDef="let item">
                <a mat-button color="primary" [routerLink]="'/orders/info/' + item.number" target="_blank">
                    {{ item.number }}
                </a>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="userId">
            <mat-header-cell *matHeaderCellDef>
                ID
                <br />
                пользователя
            </mat-header-cell>
            <mat-cell *matCellDef="let item">
                <a mat-button color="primary" [routerLink]="'/user/view/' + item.userId" target="_blank">
                    {{ item.userId }}
                </a>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="createdAt">
            <mat-header-cell *matHeaderCellDef>Создан</mat-header-cell>
            <mat-cell *matCellDef="let item">
                {{ item.createdAt | amDateFormat: 'DD.MM.YYYY г. H:mm' }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="completedAt">
            <mat-header-cell *matHeaderCellDef>Завершен</mat-header-cell>
            <mat-cell *matCellDef="let item">
                {{ item.completedAt | amDateFormat: 'DD.MM.YYYY г. H:mm' }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="product">
            <mat-header-cell *matHeaderCellDef>Продукт</mat-header-cell>
            <mat-cell *matCellDef="let item">
                {{ item.productName }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="price">
            <mat-header-cell *matHeaderCellDef>Цена</mat-header-cell>
            <mat-cell *matCellDef="let item" style="font-weight: 600;">
                <div fxLayout="column">
                    <span>{{ item.price | number }}</span>
                    <small *ngIf="item.orderData?.withdraw_from_balance" class="orders-list__promocode">
                        С баланса {{ item.orderData.withdraw_from_balance }}
                    </small>
                    <small *ngIf="item.installment || item.isInstallment" class="orders-list__mark">Рассрочка</small>
                    <small *ngIf="item.vendor === 'gift'" class="orders-list__mark">GIFT</small>
                    <small *ngIf="item.reccurent || item.isRecurrent" class="orders-list__mark">Рекурент</small>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="paymentName">
            <mat-header-cell *matHeaderCellDef>Платежная система</mat-header-cell>
            <mat-cell *matCellDef="let item">
                {{ item.paymentName }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="platform">
            <mat-header-cell *matHeaderCellDef fxFlex="100px">Платформа</mat-header-cell>
            <mat-cell *matCellDef="let item" fxFlex="100px">
                {{ item.platform }}
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <mat-paginator
        (page)="emitPageEvent($event)"
        [pageSizeOptions]="pageSizeOptions"
        [pageIndex]="pageIndex"
        [pageSize]="pageSize"
        [length]="length"
    ></mat-paginator>
</ng-container>

<ng-template #emptyOrders>
    <mat-card-subtitle>Нет заказов</mat-card-subtitle>
</ng-template>
