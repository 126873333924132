export const PriceClassList = [
    '5452-akciya-v-chest-dnya-kosmosa',
    '5452-akciya-v-chest-dnya-kosmosa-dlya-b',
    '5452-akciya-v-chest-dnya-kosmosa-dlya-c',
    '4-million',
    'ab-welcome',
    '23-february-2020-crown',
    '1651-chernaya-pyatnica-rozovaya',
    '3379-chernaya-pyatnica-cherno-ryzhaya',
    '4215-nachalo-sentyabrya',
    '4221-kiberponedelnik-neonovyy',
    '4382-skoro-ceny-stanut-vyshe',
    '6064-den-rossii',
    '6124-leto-apelsinchiki',
    '6125-den-molodezhi',
    'rasprodazha-kursov-wkm-875-wkm-2577',
    'all_inclusive_14_days',
    'zolotaya-osen-dlya-b',
    'zolotaya-osen-990-3990',
    'osen',
    'autumn-came-2019',
    'back-to-school-2019',
    'birthday-smile-2019',
    'brain-day-2019',
    'science-day-2019',
    'car-day-2019',
    'children-day-2019',
    'family-day',
    'skidka-na-semeynye-tarify-dlya-a',
    'skidka-na-semeynye-tarify-dlya-b',
    'hellouin',
    'lucky-you-2019',
    'spring-2020',
    'starwars-2019',
    'student-day-2020',
    'thinking-2019',
    'vesna-4389',
    'wikium-birthday-2019',
    'den-rozhdenie-vikium-a',
    'den-rozhdenie-vikium-v',
    'den-rozhdenie-vikium-s',
    'winter-2020-2',
    'novyy-god-elochka-s',
    'novyy-god-elochka',
    'novyy-god-elochka-v',
    'winter-2020-2',
    'novyy-god-ded-moroz',
    'novyy-god-ded-moroz-v',
    'novyy-god-ded-moroz-s',
    'epic-sale',
    'black-friday-2021',
    'helloween-2021',
    'shopping-day-2021',
    'psychologist-day-2021',
    'secret-tariff-2021',
    'black-friday-2021-26-11',
    'black-friday-rehearsal-2021',
    'cyber-monday-2021',
    'new-year-2021',
    'christmas-2022',
    'welcome-2022',
    '14-february-2022',
    '23-february-2022',
    'black-friday-sparkles-2022',
    'halloween-2022',
    'shopping-day-2022',
    'black-friday-2022-ultra-liquidation',
    'black-friday-gaming-2022',
    'ny2023sale',
    'ny2023sale',
    'likvid-super-eternal',
    '8march2023sale',
    'likvid_may2023',
    'black-friday-starlines-2023',
];
