import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthSecurity } from '../security.service';

@Injectable({
    providedIn: 'root',
})
export class AuthRoleActivate implements CanActivate {
    constructor(private router: Router, private security: AuthSecurity) {}

    async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        if (!route.data['role']) {
            this.router.navigate(['/']);
            return false;
        }

        const isGrant = await this.security.isGrant(route.data['role']);

        if (!isGrant) {
            this.router.navigate(['/signin']);
        }

        return isGrant;
    }
}
