import { Injectable } from '@angular/core';
import { Rpc } from '@app/shared/rpc';
import { Template } from '@models/email/template';
import { Schedule } from '@models/email/schedule';
import { PaginationList } from '@models/pagination-list';

@Injectable({
    providedIn: 'root',
})
export class EmailApiService {
    constructor(protected rpc: Rpc) {}

    getTemplatesList(pageIndex = 0, pageSize = 25, search = null): Promise<PaginationList<Template>> {
        return this.rpc.call('email.template.list', {
            pageIndex: pageIndex,
            pageSize: pageSize,
            search: search,
        });
    }

    async getAllTemplates(): Promise<Template[]> {
        return this.rpc.call('email.template.getAll');
    }

    async sendTestTemplate(id: number): Promise<boolean> {
        return this.rpc.call('email.template.sendTest', [id]);
    }

    async createTemplate(name, source, campaign, subject, sender): Promise<Template> {
        return this.rpc.call('email.template.create', {
            name: name,
            source: source,
            campaign: campaign,
            subject: subject,
            sender: sender,
        });
    }

    async updateTemplate(id, name, source, campaign, subject, sender): Promise<Template> {
        return this.rpc.call('email.template.update', {
            id: id,
            name: name,
            source: source,
            campaign: campaign,
            subject: subject,
            sender: sender,
        });
    }

    async createSchedule(
        name: string,
        cohortSlug: string,
        templateName: string,
        campaign: string,
        priority: number,
        sender: string,
        sendAt: string,
        enableListUnsubscribe: boolean,
    ): Promise<Schedule> {
        return this.rpc.call('email.schedule.create', {
            name: name,
            cohortSlug: cohortSlug,
            templateName: templateName,
            campaign: campaign || null,
            priority: priority || 100,
            sender: sender || null,
            sendAt: sendAt,
            enableListUnsubscribe: enableListUnsubscribe
        });
    }

    async updateSchedule(
        id: number,
        name: string,
        cohortSlug: string,
        templateName: string,
        campaign: string,
        priority: number,
        sender: string,
        sendAt: string,
        enableListUnsubscribe: boolean,
    ): Promise<Schedule> {
        return this.rpc.call('email.schedule.update', {
            id: id,
            name: name,
            cohortSlug: cohortSlug,
            templateName: templateName,
            campaign: campaign || null,
            priority: priority || 100,
            sender: sender || null,
            sendAt: sendAt,
            enableListUnsubscribe: enableListUnsubscribe
        });
    }

    async getSchedulesList(pageIndex = 0, pageSize = 25): Promise<PaginationList<Schedule>> {
        return this.rpc.call('email.schedule.list', {
            pageIndex: pageIndex,
            pageSize: pageSize,
        });
    }

    async getAllSchedules(): Promise<Schedule[]> {
        return this.rpc.call('email.schedule.getAll');
    }

    async setScheduleStatus(id: number, status: string): Promise<boolean> {
        return this.rpc.call('email.schedule.setStatus', {
            id: id,
            status: status,
        });
    }

    async renderTemplate(id: number): Promise<string> {
        return this.rpc.call('email.template.render', {
            id: id,
        });
    }

    async renderTemplateByName(name: string): Promise<string> {
        return this.rpc.call('email.template.renderByName', {
            name: name,
        });
    }

    async templateGetById(id: number): Promise<Template> {
        return this.rpc.call('email.template.get', {
            id: id,
        });
    }
    async templateGetByName(name: string): Promise<Template> {
        return this.rpc.call('email.template.getByName', {
            name: name,
        });
    }
}
