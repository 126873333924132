import { ElementRef, Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

const XLSX_EXTENSION = '.xlsx';

@Injectable({
    providedIn: 'root',
})
export class XlsxManagerService {
    private getFileName(name: string) {
        let timeSpan = new Date().toISOString();
        let sheetName = name || 'data';
        let fileName = `${sheetName}-${timeSpan}`;
        return {
            sheetName,
            fileName,
        };
    }

    exportTableToExcel(element: ElementRef, name?: string) {
        //only table rendering by tags: table, tr, and td

        let { sheetName, fileName } = this.getFileName(name);
        const wb = XLSX.utils.table_to_book(element.nativeElement, <XLSX.Table2SheetOpts>{
            sheet: sheetName,
        });
        XLSX.writeFile(wb, fileName + XLSX_EXTENSION);
    }

    exportArrayToExcel(arr: any[], name?: string) {
        let { sheetName, fileName } = this.getFileName(name);

        const wb = XLSX.utils.book_new(),
            ws = XLSX.utils.json_to_sheet(arr);

        XLSX.utils.book_append_sheet(wb, ws, sheetName);
        XLSX.writeFile(wb, fileName + XLSX_EXTENSION);
    }

    exportArrayToExcelWithCustomColumns(arr: any[], customHeaders: { [key: string]: string }, name?: string) {
        const convertedData = [];

        arr.forEach(item => {
            let res: { [key: string]: any } = {};

            for (const key in customHeaders) {
                //дробим на вложенность
                const keys = key.split('.');
                let value = this.getObjectValue(item, keys);

                if (typeof value === 'object' && value !== null) {
                    value = JSON.stringify(value);
                }

                const newKey = customHeaders[key];

                res[newKey] = value;
            }

            convertedData.push(res);
        });

        this.exportArrayToExcel(convertedData, name);
    }

    private getObjectValue(nestedObj: object, pathArr: string[]) {
        return pathArr.reduce((obj, key) => (obj && obj[key] !== 'undefined' ? obj[key] : null), nestedObj);
    }
}
