import { Component, Input, ViewChild } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NgModel,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
} from '@angular/forms';
import moment from 'moment';

@Component({
    selector: 'app-date-time-selector',
    templateUrl: './date-time-selector.component.html',
    styleUrls: ['./date-time-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: DateTimeSelectorComponent,
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: DateTimeSelectorComponent,
            multi: true,
        },
    ],
})
export class DateTimeSelectorComponent implements ControlValueAccessor, Validator {
    @Input() label: string = 'Дата';
    @Input() min: string | moment.Moment;
    @Input() required = false;

    value: string;

    @ViewChild('datetime') datetime: NgModel;

    _onChange: (value: string | null) => void;

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    updateValue(date) {
        if (moment(date).isValid()) {
            date = moment(date).format('YYYY-MM-DD HH:mm:ss');
        } else {
            date = null;
        }

        this._onChange(date);
    }

    registerOnTouched(fn: any): void {}

    writeValue(value: string): void {
        this.value = value;
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (this.min && moment(control.value).isBefore(moment(this.min))) {
            return { invalidMin: true };
        }

        return null;
    }
}
