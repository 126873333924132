export class CacheItem {
    /**
     * Created at timestamp.
     */
    private readonly createdAt: number;

    /**
     * @param {string} key
     * @param value
     * @param {number} lifetime
     */
    constructor(private key: string, private value: any, private lifetime: number) {
        this.createdAt = Date.now();
    }

    /**
     * Get cache key.
     */
    getKye(): string {
        return this.key;
    }

    /**
     * Get cache value.
     */
    getValue(): any {
        return this.value;
    }

    /**
     * Is cache valid.
     */
    isValid(): boolean {
        return this.lifetime > 0 && Date.now() - this.createdAt < this.lifetime;
    }
}
