import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable({
    providedIn: 'root',
})
export class TokenSubject extends BehaviorSubject<string | null> {
    constructor() {
        super(null);
    }
}
